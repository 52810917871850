import { formatDate } from './dateTime';

/** @namespace dateLabelText*/

/**
 * @method dateLabelText
 * @param {string}  dateLabel date of label
 * @param {string}  template date of template
 * @author Hanna Kulai <hkulai@inspirebrands.com>
 * @added 2023-03-21
 * @version 1.0
 * @memberOf dateLabelText
 * @returns {string|null} dateLabelText
 * @example
 * const transfromDate = (date: string) => dateLabelText(date);
 */

export const dateLabelText = (dateLabel: string, template = 'MM/dd/yyyy'): string | null =>
    dateLabel ? `Expires ${formatDate(dateLabel, template)}` : null;
