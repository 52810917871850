/**@namespace ApplyPromocodeService*/

import { ApplyPromocodeRequest, WebExperienceApi } from '../../../@generated/webExpApi/apis/WebExperienceApi';
import { IApplyPromocodeRequestModel } from '../../../@generated/webExpApi/models/IApplyPromocodeRequestModel';
import { IOfferModel } from '../../../@generated/webExpApi/models/IOfferModel';
import { SellingChannelNamesModel } from '../../../@generated/webExpApi/models/SellingChannelNamesModel';
import { getAuthorizationConfig } from '../../helpers/getAuthorizationConfig';
import createErrorWrapper from '../createErrorWrapper';

/**
 * Creates a service for applying a promo code
 * @class ApplyPromocodeService
 * @author Pavel Shpakovich <pavel.shpakovich@inspirebrands.com>
 * @added 2022-03-23
 * @version 1.0.0
 * @memberOf  ApplyPromocodeService
 */
class ApplyPromocodeService {
    applyPromocodeApi: (request: ApplyPromocodeRequest, initOverrides?: RequestInit) => Promise<IOfferModel>;

    /**
     * Create a ApplyPromocodeService.
     * @param {number} JWT- The JWT token.
     */
    constructor(JWT: string) {
        const expApi = new WebExperienceApi(getAuthorizationConfig(JWT));

        this.applyPromocodeApi = createErrorWrapper<IOfferModel, ApplyPromocodeRequest, RequestInit>(
            'applyPromocode',
            expApi.applyPromocode.bind(expApi)
        );
    }
    /**
     * Make a request to web-exp-api to apply a promo code.
     * @method applyPromocode
     * @author Pavel Shpakovich <pavel.shpakovich@inspirebrands.com>
     * @added 2022-03-23
     * @version 1.0
     * @param {IApplyPromocodeRequestModel} payload - data needed for applying a promo code
     * @param {RequestInit} initOverrides - additional options
     * @return {Promise<IOfferModel>} - applied promo code response
     */
    applyPromocode(payload: IApplyPromocodeRequestModel, initOverrides?: RequestInit): Promise<IOfferModel> {
        return this.applyPromocodeApi(
            {
                iApplyPromocodeRequestModel: payload,
                sellingChannel: SellingChannelNamesModel.Weboa,
            },
            initOverrides
        );
    }
}

export default ApplyPromocodeService;
