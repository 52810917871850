import React from 'react';
import classnames from 'classnames';
import { isValid } from '../../../common/helpers/dateTime';

import { useBag } from '../../../redux/hooks';
import useRewards from '../../../redux/hooks/useRewards';

import styles from './item.module.css';
import { InspireLinkButton } from '../../atoms/link';
import { useRouter } from 'next/router';
import useDeal from '../../../common/hooks/useDeal';
import { OFFER_HEADER_CLASS } from './constants';
import { dateLabelText } from '../../../common/helpers/dataLabelText';

interface IDealItemProps {
    dealId: string;
    subTotalBeforeDiscounts: number;
    warningMessage?: string;
    isOfferApplicable: boolean;
    isPromocodeApplied?: boolean;
}

function BagDealItem(props: IDealItemProps): JSX.Element {
    const { dealId, warningMessage, isOfferApplicable, isPromocodeApplied = false } = props;

    const {
        getOfferById,
        actions: { removePromocode },
    } = useRewards();
    const bag = useBag();
    const router = useRouter();
    const offer = getOfferById(dealId);
    const { getDealRedirectUrlByRedeem } = useDeal();

    if (!offer) return null;

    const expiresDate = isValid(new Date(offer?.endDateTime)) ? dateLabelText(offer?.endDateTime, 'MM/dd') : '';

    const handleRemoveDeal = () => {
        if (!bag.isDiscountLoading) {
            bag.actions.removeDealFromBag();
        }

        if (isPromocodeApplied) {
            removePromocode(offer.userOfferId);
        }
    };

    const handleSelectItems = () => {
        bag.actions.toggleIsOpen({ isOpen: false });
        router.push(
            getDealRedirectUrlByRedeem(
                offer.type,
                offer.applicability?.eligibleIds || [],
                offer.applicability?.buyCount,
                offer.applicability?.isIncludesAll
            )
        );
    };

    const renderDealInfo = () => {
        if (isPromocodeApplied) {
            return (
                <span className={classnames('t-subheader-universal-smaller', OFFER_HEADER_CLASS, styles.offerHeader)}>
                    Promo Code: {offer.code}
                </span>
            );
        }
        return (
            <>
                <span className={classnames('t-subheader-universal-smaller', OFFER_HEADER_CLASS, styles.offerHeader)}>
                    {offer.name}
                </span>
                {!!expiresDate && (
                    <span className={classnames(styles.offerDateHeader, 't-paragraph-hint')}>{expiresDate}</span>
                )}
            </>
        );
    };

    return (
        <>
            <div className={styles.productDealContainer} data-testid="applied-deal">
                <div className={styles.dealInfoContainer}>{renderDealInfo()}</div>
                <div className={styles.offerButtonSection}>
                    {isOfferApplicable && warningMessage && (
                        <InspireLinkButton
                            role="link"
                            aria-label="select-deal-items"
                            data-gtm-id={`select-deal-items`}
                            onClick={handleSelectItems}
                            linkType="secondary"
                            dataTestId="select-deal-items-button"
                            className={styles.offerButton}
                        >
                            Select Items
                        </InspireLinkButton>
                    )}
                    <InspireLinkButton
                        role="link"
                        aria-label="remove-deal"
                        data-gtm-id={`remove-${name}`}
                        onClick={handleRemoveDeal}
                        linkType="secondary"
                        dataTestId="remove-deal-button"
                        className={styles.offerButton}
                    >
                        Remove
                    </InspireLinkButton>
                </div>
            </div>
            {warningMessage && (
                <div className={styles.dealWarning} data-testid="deal-warning">
                    {warningMessage}
                </div>
            )}
        </>
    );
}

export default BagDealItem;
