import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useMemo, useState } from 'react';
import { useFeatureFlags } from '../../redux/hooks/useFeatureFlags';
import { authorizationHeaderBuilder } from '../helpers/accountHelper';
import DealsService from '../services/customerService/deals';

export const useDealsService = (): DealsService => {
    const { isAuthenticated, user, getIdTokenClaims } = useAuth0();
    const { featureFlags } = useFeatureFlags();
    const [idToken, setIdToken] = useState<string>('');

    useEffect(() => {
        if (featureFlags.account) {
            getIdTokenClaims().then((res) => {
                setIdToken(res?.__raw);
            });
        }
    }, [isAuthenticated, user, featureFlags.account, getIdTokenClaims]);

    const dealsService = useMemo(() => {
        return new DealsService(authorizationHeaderBuilder(idToken));
    }, [idToken]);

    return dealsService;
};
