import classnames from 'classnames';
import React from 'react';
import { TOffersUnionModel } from '../../../../@generated/webExpApi';
import { GTM_AUTO_SUGGEST_APPLY_DEAL } from '../../../../common/services/gtmService/constants';
import { useAppDispatch } from '../../../../redux/store';
import { APPLY_DEAL_TITLE } from './bagItemSuggestedDealTop.constants';

import styles from './bagItemSuggestedDealTop.module.css';

interface IProps {
    offer: TOffersUnionModel;
    onApplyDeal: (dealId: string, quantity: number) => void;
    productQuantityInBag: number;
}

const BagSingleItemSuggestedDealTop: React.FC<IProps> = ({ offer, productQuantityInBag, onApplyDeal }) => {
    const dealName = offer.name;

    const dispatch = useAppDispatch();

    const handleApplyDeal = () => {
        onApplyDeal(offer.userOfferId, productQuantityInBag);
        dispatch({ type: GTM_AUTO_SUGGEST_APPLY_DEAL, payload: { offerName: dealName } });
    };

    return (
        <div className={classnames(styles.container, 't-paragraph-hint')}>
            <span className={classnames(styles.dealName, 'truncate')}>{dealName}. </span>
            &nbsp;
            <span
                role="button"
                aria-label="Apply deal"
                data-gtm-id={`Apply deal-${dealName}`}
                data-testid="apply-deal"
                onClick={handleApplyDeal}
                className={styles.applyDealButton}
            >
                {APPLY_DEAL_TITLE}
            </span>
        </div>
    );
};

export default BagSingleItemSuggestedDealTop;
