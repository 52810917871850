import React, { FC } from 'react';
import classnames from 'classnames';

import { InspireCmsEntry } from '../../../common/types';
import { IProductFields } from '../../../@generated/@types/contentful';
import { useDomainMenu } from '../../../redux/hooks';
import RadioButton from '../radioButton';
import Checkbox from '../checkbox';

import { VerticalProductCardContent } from '../verticalProductCard/verticalProductCardContent';
import verticalProductCardStyles from '../verticalProductCard/verticalProductCard.module.css';
import styles from './suggestProductCardContainer.module.css';
import { IDealDisplayProduct } from '../../../redux/types';
import { ICON_SIZE, SHOW_PRICE } from './suggestProductCardContainer.constants';
import { CONSTANTS } from '../../../constants/en/commons';

interface ISuggestProductCardContainerProps {
    displayProduct: IDealDisplayProduct;
    item: InspireCmsEntry<IProductFields>;
    isSelected: boolean;
    maxQuantity: number;
    handleProductSelect: (id: string) => void;
    isDisabled: boolean;
}

export const SuggestProductCardContainer: FC<ISuggestProductCardContainerProps> = (props): JSX.Element => {
    const { item, isSelected, maxQuantity, displayProduct, handleProductSelect, isDisabled } = props;
    const { TEMPORARY_UNAVAILABLE } = CONSTANTS;
    const { fields } = item;

    const {
        displayName,
        displayProductDetails: { isAvailable, calories, productId, price, isSaleable },
    } = displayProduct;

    const handleCardLinkClick = () => {
        if (typeof handleProductSelect === 'function') {
            handleProductSelect(productId);
        }
    };

    const { loading } = useDomainMenu();

    const renderSelectionType = () => {
        if (maxQuantity === 1) {
            return (
                <RadioButton
                    checked={isSelected}
                    inputProps={{ 'aria-label': `Select ${displayName}` }}
                    value={fields.productId}
                    tabIndex={-1}
                    className={styles.radioButton}
                    data-testid="modal-product-radio-button"
                    size={ICON_SIZE}
                />
            );
        }

        if (maxQuantity > 1) {
            return (
                <Checkbox
                    ariaLabel={`Select ${displayName}`}
                    className={styles.checkbox}
                    fieldName={fields.productId}
                    selected={isSelected}
                    tabIndex={-1}
                    dataTestId="modal-product-checkbox"
                    size={ICON_SIZE}
                />
            );
        }
    };

    return (
        <div
            className={classnames(
                verticalProductCardStyles.verticalProductCard,
                styles.suggestProductCardContainer,
                styles.container,
                {
                    [styles.disabled]: !isAvailable || isDisabled,
                }
            )}
            aria-label="Product Card"
            onClick={handleCardLinkClick}
        >
            {renderSelectionType()}
            <VerticalProductCardContent
                item={item}
                productName={displayName}
                calories={calories}
                isLoading={loading}
                shouldImageLoadEager
                isOrderAheadAvailable
                shouldShowCaloriesAndPrice
                caloriesClassName={styles.calories}
                price={SHOW_PRICE ? price : undefined}
                isSaleable={isSaleable}
            />
            {!isAvailable && (
                <div className={styles.temporarilyUnavailable}>
                    <span>{TEMPORARY_UNAVAILABLE}</span>
                </div>
            )}
        </div>
    );
};
