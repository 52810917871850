import React, { useEffect, useMemo, useRef, useState } from 'react';
import classnames from 'classnames';
import { useAuth0 } from '@auth0/auth0-react';
import { useAppDispatch } from '../../../redux/store';
import { GTM_ERROR_EVENT } from '../../../common/services/gtmService/constants';
import { GtmErrorCategory, GtmErrorEvent } from '../../../common/services/gtmService/types';

import Loader from '../../atoms/Loader';
import ReviewOrderTotal from '../../atoms/ReviewOrderTotal';

import BagItem from './item';

import styles from './index.module.css';
import { IBagRenderItem, sortBagItemsByName } from './bagModel';
import useAccount from '../../../redux/hooks/useAccount';
import {
    useDomainMenuSelectors,
    useDomainProductDrinkIds,
    useDomainProducts,
    useDrinkCarrierCondiment,
} from '../../../redux/hooks/domainMenu';
import UserDeals from '../../atoms/userDeals';
import { useBag, useOrderLocation, usePdp, useSelectedSell } from '../../../redux/hooks';
import BagDealItem from './bagDealItem';
import RecommendedProducts from '../recommendedProducts';
import Icon from '../../atoms/BrandIcon';
import { BagPromocodeContainer } from './promoCode/bagPromocodeContainer/bagPromocodeContainer';
import { InspireLinkButton } from '../../atoms/link';
import { OrderLocationMethod } from '../../../redux/orderLocation';
import { TallyProductModel } from '../../../@generated/webExpApi';
import { getBagEntriesWithoutCondiments } from '../../../common/helpers/bagHelper';
import { IUnavailableItem } from '../../../common/hooks/useBagUnavailableItems';
import CaloriesLegalSection from '../caloriesLegalSection/caloriesLegelSection';
import { ICaloriesLegal } from '../../../@generated/@types/contentful';
import {
    ALLOW_LOGIN_FROM_BAG,
    APPLIED_DEAL_TEXT,
    APPLIED_PROMO_CODE_TEXT,
    ARE_CONDIMENTS_ENABLED,
    SHOULD_ALWAYS_SHOW_USER_DEALS,
    SHOULD_SHOW_DISCOUNT_ON_BAG,
    SHOW_CALORIES_LEGAL,
} from './constants';
import { BagDrinkCarrier } from './bagDrinkCarrier/bagDrinkCarrier';
import BagSuggestedDeal from './bagSuggestedDeal';
import useSuggestedDeal from '../../../redux/hooks/useSuggestedDeal';
import { DealsTypesMap, MainDealsTypesByStructure } from '../../../common/helpers/dealHelper';
import BagSingleItemSuggestedDealTop from './bagItemSuggestedDealTop';
import { useCheckIsCondiment } from '../../../common/hooks/useCheckIsCondiment';
import { isPromoCodeEnabled } from '../../../lib/getFeatureFlags';

export interface IBagContentProps {
    totalPrice: string;
    isMenuLoading: boolean;
    renderItems: IBagRenderItem[];
    onRemoveUnavailable: (unavailableEntries: TallyProductModel[]) => void;
    totalDiscount: number | string;
    subTotal: string;
    subTotalBeforeDiscounts: number;
    tooltipText?: string | JSX.Element;
    isOverLimitForOrder?: boolean;
    unavailableItems: IUnavailableItem[];
    caloriesLegal: ICaloriesLegal;
    handleCondimentsWindow: (payload: boolean) => void;
    warningMessage?: string;
    isOfferApplicable?: boolean;
    isDiscountLoading?: boolean;
    totalPromoCodeDiscount?: number | string;
    isPromocodeApplied?: boolean;
}

const BagContent = ({
    totalPrice,
    isMenuLoading,
    renderItems,
    onRemoveUnavailable,
    totalDiscount,
    totalPromoCodeDiscount,
    subTotal,
    subTotalBeforeDiscounts,
    tooltipText,
    isOverLimitForOrder,
    unavailableItems,
    caloriesLegal,
    handleCondimentsWindow,
    warningMessage,
    isOfferApplicable,
    isDiscountLoading,
    isPromocodeApplied = false,
}: IBagContentProps): JSX.Element => {
    const [isCondimentsLimitReached, setIsCondimentsLimitReached] = useState(false);
    const { account } = useAccount();
    const { loginWithRedirect } = useAuth0();
    const { recommendedProductIds } = useSelectedSell();
    const { dealId, actions, entriesMarkedAsRemoved } = useBag();
    const dispatch = useAppDispatch();
    const { isCurrentLocationOAAvailable: isOnlineOrderAvailable, method, condimentsLimit } = useOrderLocation();
    const productDrinkIds = useDomainProductDrinkIds();
    const { selectDefaultTallyItem } = useDomainMenuSelectors();
    const {
        actions: { resetPdpCondimentsState },
    } = usePdp();

    const isCondiment = useCheckIsCondiment();
    const drinkCarrier = useDrinkCarrierCondiment();
    const drinkCarrierId = drinkCarrier?.displayProductDetails?.productId;

    const { availableRenderItems, unavailableRenderItems } = useMemo(
        () =>
            renderItems.reduce(
                (acc, item) => {
                    if (item.isAvailable) {
                        return { ...acc, availableRenderItems: [...acc.availableRenderItems, item] };
                    }

                    return { ...acc, unavailableRenderItems: [...acc.unavailableRenderItems, item] };
                },
                { availableRenderItems: [] as IBagRenderItem[], unavailableRenderItems: [] as IBagRenderItem[] }
            ),
        [renderItems]
    );
    const condimentsCount = useMemo(
        () =>
            availableRenderItems
                .filter((item) => isCondiment(item.entry.productId))
                .reduce((prev, acc) => prev + acc.entry.quantity, 0),
        [availableRenderItems]
    );

    const { current: initialCount } = useRef<number>(condimentsCount);

    useEffect(() => {
        if (initialCount !== condimentsCount) {
            resetPdpCondimentsState();
        }
    }, [initialCount, condimentsCount]);

    const hasBagDrinkCarrier = renderItems.some((item) => item.entry.productId === drinkCarrierId);
    const hasBagDrinkProduct = renderItems.some((item) => productDrinkIds.includes(item.entry.productId as never));
    const isDrinkCarrierAvailable = (hasBagDrinkCarrier || hasBagDrinkProduct) && drinkCarrier;

    const unavailableItemsByLineItemId = useMemo(
        () =>
            unavailableItems.reduce((acc, item) => {
                return { ...acc, [item.lineItemId]: item };
            }, {} as Record<string, IUnavailableItem>),
        [unavailableItems]
    );

    const unavailableSortedRenderItems = sortBagItemsByName(
        unavailableRenderItems,
        useDomainProducts(unavailableRenderItems.map((item) => item.entry.productId))
    ).sort((a, b) => {
        // place whole unavailable items below
        return (
            Number(unavailableItemsByLineItemId[a.entry.lineItemId].isWholeProductUnavailable) -
            Number(unavailableItemsByLineItemId[b.entry.lineItemId].isWholeProductUnavailable)
        );
    });

    const availableBagEntriesWithoutCondiments = getBagEntriesWithoutCondiments(
        renderItems,
        isCondiment,
        entriesMarkedAsRemoved
    );

    const handleRemoveAll = () => {
        onRemoveUnavailable(unavailableSortedRenderItems?.map((item) => item.entry));
    };

    useEffect(() => {
        if (unavailableRenderItems?.length) {
            const payload = {
                ErrorCategory: GtmErrorCategory.BAG_ERROR,
                ErrorDescription: 'These items are currently unavailable at this location',
            } as GtmErrorEvent;

            dispatch({ type: GTM_ERROR_EVENT, payload });
        }
    }, [unavailableRenderItems, dispatch]);

    useEffect(() => {
        setIsCondimentsLimitReached(condimentsCount >= condimentsLimit);
    }, [condimentsCount, condimentsLimit]);

    const suggestedDeal = useSuggestedDeal();
    const { matchingItemWithLowestPrice } = suggestedDeal;

    const isOfferApplicableForSingleBagItem =
        (suggestedDeal.fullMatchOffer?.applicability.buyCount === 1 &&
            DealsTypesMap[suggestedDeal.fullMatchOffer?.type]?.mainType === MainDealsTypesByStructure.BuyX) ||
        suggestedDeal.partiallyMatchOffer?.applicability.eligibleIds?.length === 1 ||
        suggestedDeal.fullMatchOffer?.applicability.eligibleIds?.length === 1;

    const bagItemsRef = useRef<HTMLDivElement>(null);

    const handleApplySingleDeal = (dealId: string, quantity: number) => {
        actions.addDealToBag({ id: dealId });

        if (
            suggestedDeal.partiallyMatchOffer?.applicability.buyCount > 1 &&
            suggestedDeal.partiallyMatchOffer?.applicability.eligibleIds.length === 1 &&
            suggestedDeal.partiallyMatchOffer?.applicability.buyCount > quantity
        ) {
            const defaultTallyItem = selectDefaultTallyItem(
                suggestedDeal.partiallyMatchOffer?.applicability.eligibleIds[0].menuId
            );

            for (let count = 0; count < suggestedDeal.partiallyMatchOffer?.applicability.buyCount - quantity; count++) {
                actions.putToBag({
                    pdpTallyItem: defaultTallyItem,
                });
            }
        }

        bagItemsRef.current?.scroll({
            top: 0,
            behavior: 'smooth',
        });
    };

    return isMenuLoading ? (
        <span className={styles.loader}>
            <Loader size={50} />
        </span>
    ) : (
        <div className={styles.bagItems} ref={bagItemsRef}>
            {account && dealId && isOnlineOrderAvailable && (
                <div className={styles.bagDealContainer}>
                    <h3 className={classnames('t-header-card-title', styles.summaryHeader)}>
                        {isPromocodeApplied ? APPLIED_PROMO_CODE_TEXT : APPLIED_DEAL_TEXT}
                    </h3>
                    {
                        <BagDealItem
                            dealId={dealId}
                            subTotalBeforeDiscounts={subTotalBeforeDiscounts}
                            warningMessage={warningMessage}
                            isOfferApplicable={isOfferApplicable}
                            isPromocodeApplied={isPromocodeApplied}
                        />
                    }
                </div>
            )}
            {SHOULD_SHOW_DISCOUNT_ON_BAG &&
                account &&
                !dealId &&
                isOnlineOrderAvailable &&
                !isOfferApplicableForSingleBagItem &&
                suggestedDeal.fullMatchOffer && <BagSuggestedDeal fullMatchOffer={suggestedDeal.fullMatchOffer} />}
            <h3
                className={classnames('t-header-card-title', styles.summaryHeader)}
                data-testid="bag-order-summary-header"
            >
                Order Summary
            </h3>
            <div role="list" aria-label="Bag items" className={styles.bagItemsList}>
                {availableRenderItems
                    ?.filter((item) => item.entry.productId !== drinkCarrierId) //No need to show drink carrier
                    .map((item) => {
                        const shouldShowSuggestedDeal =
                            SHOULD_SHOW_DISCOUNT_ON_BAG &&
                            !dealId &&
                            matchingItemWithLowestPrice?.lineItemId === item.entry.lineItemId;

                        const currentOffer = suggestedDeal.fullMatchOffer || suggestedDeal.partiallyMatchOffer;

                        return (
                            <>
                                {shouldShowSuggestedDeal && isOfferApplicableForSingleBagItem && (
                                    <BagSingleItemSuggestedDealTop
                                        offer={currentOffer}
                                        productQuantityInBag={item.entry.quantity}
                                        onApplyDeal={handleApplySingleDeal}
                                    />
                                )}

                                <BagItem
                                    entry={item.entry}
                                    entryPath={item.entryPath}
                                    key={item.entry.lineItemId}
                                    markedAsRemoved={item.markedAsRemoved}
                                    contentfulProduct={item.contentfulProduct}
                                    discountBanner={shouldShowSuggestedDeal ? 'Deal Available' : item.discountBanner}
                                    tabIndex={0}
                                    suggestedDeal={
                                        shouldShowSuggestedDeal &&
                                        !isOfferApplicableForSingleBagItem &&
                                        !suggestedDeal.fullMatchOffer
                                            ? suggestedDeal
                                            : null
                                    }
                                    isCondimentsLimitReached={isCondimentsLimitReached}
                                    condimentsLimit={condimentsLimit}
                                    hasSimpleOffer={shouldShowSuggestedDeal && isOfferApplicableForSingleBagItem}
                                />
                            </>
                        );
                    })}

                {ARE_CONDIMENTS_ENABLED && !!availableBagEntriesWithoutCondiments.length && (
                    <InspireLinkButton
                        className={styles.addCondimentsBtn}
                        linkType="primary"
                        onClick={() => handleCondimentsWindow(true)}
                    >
                        <Icon icon="action-add" size="s" />
                        <span className={styles.addCondimentsText}>Add Condiments</span>
                    </InspireLinkButton>
                )}
            </div>
            {unavailableSortedRenderItems.length > 0 && (
                <div className={styles.bagItemsList}>
                    <div className={styles.unavailableBlockControls}>
                        <h4
                            className={classnames(
                                't-header-card-title',
                                styles.summaryHeader,
                                styles.unavailableHeader
                            )}
                        >
                            Unavailable
                        </h4>
                        <InspireLinkButton
                            linkType="secondary"
                            className={styles.unavailableRemove}
                            onClick={handleRemoveAll}
                        >
                            Remove All
                        </InspireLinkButton>
                    </div>
                    <div className={classnames('t-paragraph-hint', styles.unavailableHint)}>
                        These items are currently unavailable at this location.
                    </div>
                    {unavailableSortedRenderItems.map((item) => {
                        const unavailableItem = unavailableItemsByLineItemId[item.entry.lineItemId];
                        const {
                            modifierIds,
                            subModifierIds,
                            isDefaultModifiersUnavailable,
                            childItemsIds,
                        } = unavailableItem;

                        if (unavailableItem.isWholeProductUnavailable) {
                            return (
                                <BagItem
                                    entry={item.entry}
                                    entryPath={item.entryPath}
                                    key={item.entry.lineItemId}
                                    markedAsRemoved={item.markedAsRemoved}
                                    contentfulProduct={item.contentfulProduct}
                                    isUnavailableItem={true}
                                />
                            );
                        } else {
                            return (
                                <BagItem
                                    entry={item.entry}
                                    entryPath={item.entryPath}
                                    key={item.entry.lineItemId}
                                    markedAsRemoved={item.markedAsRemoved}
                                    contentfulProduct={item.contentfulProduct}
                                    unavailableModifiers={modifierIds}
                                    unavailableChildItems={childItemsIds}
                                    isDefaultModifiersUnavailable={isDefaultModifiersUnavailable}
                                    unavailableSubModifiers={subModifierIds}
                                />
                            );
                        }
                    })}
                </div>
            )}
            {recommendedProductIds.length > 0 && <RecommendedProducts />}
            {isDrinkCarrierAvailable && (
                <BagDrinkCarrier isCondimentsLimitReached={isCondimentsLimitReached} drinkCarrier={drinkCarrier} />
            )}
            {account && (!dealId || SHOULD_ALWAYS_SHOW_USER_DEALS) && <UserDeals />}
            {!!account && isPromoCodeEnabled() && <BagPromocodeContainer disabled={!!dealId} />}
            {!account && ALLOW_LOGIN_FROM_BAG && (
                <div className={classnames('t-paragraph-small', styles.signInContainer)}>
                    <span>Love Sonic®? Become a member and discover exclusive freebies, discounts, and offers!</span>
                    <InspireLinkButton
                        className={styles.signInLink}
                        linkType="secondary"
                        onClick={() =>
                            loginWithRedirect({
                                appState: { target: window.location.pathname, loginFromBag: true },
                                isIDP: true,
                            })
                        }
                    >
                        Sign In or Create an Account to View Rewards
                    </InspireLinkButton>
                </div>
            )}
            <div className={styles.reviewTotal}>
                <ReviewOrderTotal
                    totalTax="TBD"
                    subtotal={subTotal}
                    totalPrice={totalPrice}
                    totalTaxLabel="Estimated Tax"
                    totalDiscount={totalDiscount}
                    totalPromoCodeDiscount={totalPromoCodeDiscount}
                    tooltipText={tooltipText}
                    additionalFees={method === OrderLocationMethod.DELIVERY ? 'TBD' : ''}
                    isOverLimitForOrder={isOverLimitForOrder}
                    isDiscountLoading={isDiscountLoading}
                />
            </div>
            {SHOW_CALORIES_LEGAL && (
                <CaloriesLegalSection
                    containerClassName={styles.caloriesContainer}
                    text={caloriesLegal?.fields?.message}
                ></CaloriesLegalSection>
            )}
        </div>
    );
};

export default BagContent;
