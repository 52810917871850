import { useState } from 'react';
import { getCheapestPriceFromProduct } from '../../../../lib/domainProduct';
import { useBag, useOrderLocation, usePdp } from '../../../../redux/hooks';
import { useDomainProduct } from '../../../../redux/hooks/domainMenu';
import { IDisplayProduct } from '../../../../redux/types';

interface IUseDrinkCarrier {
    condiment: IDisplayProduct;
    condimentLimitMessage: string;
    actions: {
        handleQuantity: (quantity: number) => void;
    };
}

export const useBagDrinkCarrier = (drinkCarrier: IDisplayProduct): IUseDrinkCarrier => {
    const {
        bagEntries,
        actions: { putCondimentsToBag },
    } = useBag();
    const {
        actions: { onCondimentChange },
    } = usePdp();
    const { currentLocation, condimentsLimit } = useOrderLocation();
    const domainDrinkCarrier = useDomainProduct(drinkCarrier.displayProductDetails.productId);

    const [condiment, setCondiment] = useState(() => {
        const bagDrinkCarrier = bagEntries.find(
            (item) => item.productId === drinkCarrier.displayProductDetails.productId
        );
        if (!bagDrinkCarrier) return drinkCarrier;
        return {
            ...drinkCarrier,
            displayProductDetails: {
                ...drinkCarrier.displayProductDetails,
                quantity: bagDrinkCarrier.quantity,
            },
        };
    });

    const handleQuantity = (quantity: number): void => {
        setCondiment((condiment) => {
            return {
                ...condiment,
                displayProductDetails: {
                    ...condiment.displayProductDetails,
                    quantity,
                },
            };
        });

        saveCondiment(quantity);
    };

    const saveCondiment = (quantity: number) => {
        const { productId, productGroupId } = condiment.displayProductDetails;
        const item = {
            [productId]: {
                productId,
                quantity,
                price: getCheapestPriceFromProduct(domainDrinkCarrier?.[productId], currentLocation),
            },
        };

        if (quantity !== 0) {
            onCondimentChange({
                modifierGroupId: productGroupId,
                modifierId: productId,
                quantity,
            });
        }

        putCondimentsToBag(item);
    };

    return {
        condiment,
        condimentLimitMessage: `You've reached the condiment limit (${condimentsLimit}).`,
        actions: {
            handleQuantity,
        },
    };
};
