import React, { useCallback, useEffect, useRef } from 'react';
import classnames from 'classnames';
import BagContent, { IBagContentProps } from './bagContent';
import { IEmptyShoppingBagFields } from '../../../@generated/@types/contentful';
import { useAccount, useBag, useOrderLocation, useNotifications } from '../../../redux/hooks';
import BagDealItem from './bagDealItem';
import NotFound from '../../molecules/notFound';
import { EXTEND_HEADER, EXTEND_ICON, EXTEND_PARAGRAPH, SHOULD_SHOW_DISCOUNT_ON_BAG } from './constants';
import UserDeals from '../../atoms/userDeals';
import { usePrevious } from '../../../common/hooks/usePreviousState';
import { IRewardsDiscountDSResponseModel, TallyProductModel } from '../../../@generated/webExpApi';
import { formatPrice } from '../../../lib/domainProduct';
import { mapDiscountItemResponse } from '../../../common/helpers/bagHelper';

import styles from './index.module.css';

interface IBagContentContainerProps extends IBagContentProps {
    isBagEmpty?: boolean;
    emptyBagFields?: IEmptyShoppingBagFields;
    bagTallyEntries?: TallyProductModel[];
    handleCondimentsWindow: (payload: boolean) => void;
    getOffersDiscount: (
        bagEntries: TallyProductModel[],
        subTotal: string,
        orderTime?: Date,
        dealId?: string,
        customerId?: string
    ) => Promise<IRewardsDiscountDSResponseModel>;
}

const BagContentContainer = ({
    isMenuLoading,
    renderItems,
    warningMessage,
    isOfferApplicable,
    onRemoveUnavailable,
    subTotal,
    subTotalBeforeDiscounts,
    tooltipText,
    isOverLimitForOrder,
    unavailableItems,
    caloriesLegal,
    isBagEmpty,
    emptyBagFields: { image, description, header },
    handleCondimentsWindow,
    bagTallyEntries,
    getOffersDiscount,
}: IBagContentContainerProps) => {
    const {
        dealId,
        bagEntries,
        isOpen,
        entriesMarkedAsRemoved,
        bagDiscount,
        isDiscountLoading,
        actions: { setDiscountIsLoading, setDiscountToBag, resetDiscountsOnBag },
    } = useBag();
    const { isCurrentLocationOAAvailable: isOnlineOrderAvailable } = useOrderLocation();
    const { account } = useAccount();

    const bagEntriesCount = bagEntries.reduce((result, currentItem) => {
        if (!entriesMarkedAsRemoved.includes(currentItem.lineItemId)) {
            return result + currentItem?.quantity;
        }

        return result;
    }, 0);

    const prevBagEntriesCount = usePrevious(bagEntriesCount);
    const prevDealId = usePrevious(dealId);

    const isInitialRender = useRef(true);

    const prevWarningMessage = usePrevious(warningMessage);

    const {
        actions: { enqueueError },
    } = useNotifications();

    const handleGetDiscount = useCallback(() => {
        if (SHOULD_SHOW_DISCOUNT_ON_BAG && bagEntriesCount > 0 && account && dealId && isOpen) {
            setDiscountIsLoading(true);

            getOffersDiscount(
                bagTallyEntries,
                String(subTotalBeforeDiscounts.toFixed(2)),
                null,
                dealId,
                account?.idpCustomerId
            )
                .then((response) => {
                    setDiscountToBag({
                        items: response.order.items.map(mapDiscountItemResponse),
                        discountAmount: Number(response.order.subTotal) - Number(response.order.discountedPrice),
                        subTotal: Number(response.order.subTotal),
                    });
                })
                .catch(() => {
                    resetDiscountsOnBag();

                    enqueueError({
                        message: 'Issue loading discounts. Please refresh the page.',
                    });
                })
                .finally(() => {
                    setDiscountIsLoading(false);
                });
        }
    }, [
        account,
        bagEntriesCount,
        bagTallyEntries,
        dealId,
        getOffersDiscount,
        isOpen,
        setDiscountToBag,
        setDiscountIsLoading,
        resetDiscountsOnBag,
        subTotalBeforeDiscounts,
        enqueueError,
    ]);

    useEffect(() => {
        if (
            !warningMessage &&
            (bagEntriesCount !== prevBagEntriesCount || dealId !== prevDealId || (isInitialRender.current && isOpen))
        ) {
            handleGetDiscount();
        }

        if (
            (warningMessage && isInitialRender.current) ||
            (warningMessage !== prevWarningMessage && warningMessage) ||
            (dealId !== prevDealId && !dealId) ||
            (!dealId && isInitialRender.current)
        ) {
            resetDiscountsOnBag();
        }

        if (isInitialRender.current) {
            isInitialRender.current = false;
        }
    }, [
        handleGetDiscount,
        resetDiscountsOnBag,
        bagEntriesCount,
        dealId,
        isOpen,
        prevBagEntriesCount,
        prevWarningMessage,
        prevDealId,
        warningMessage,
    ]);

    if (isBagEmpty) {
        return (
            <div className={styles.emptyBagItems}>
                {account && dealId && isOnlineOrderAvailable && (
                    <div className={styles.bagDealContainer}>
                        <h3 className={classnames('t-header-card-title', styles.summaryHeader)}>applied deal</h3>
                        {
                            <BagDealItem
                                dealId={dealId}
                                subTotalBeforeDiscounts={subTotalBeforeDiscounts}
                                warningMessage={warningMessage}
                                isOfferApplicable={isOfferApplicable}
                            />
                        }
                    </div>
                )}
                <NotFound
                    icon={image}
                    heading={header}
                    body={description}
                    iconClassName={classnames({ [styles[EXTEND_ICON]]: !!EXTEND_ICON })}
                    headerClassName={classnames({ [styles[EXTEND_HEADER]]: !!EXTEND_HEADER })}
                    paragraphClassName={classnames({ [styles[EXTEND_PARAGRAPH]]: !!EXTEND_PARAGRAPH })}
                />
                {account && !dealId && <UserDeals />}
            </div>
        );
    }

    return (
        <BagContent
            isMenuLoading={isMenuLoading}
            totalPrice={formatPrice(subTotalBeforeDiscounts - bagDiscount)}
            renderItems={renderItems}
            onRemoveUnavailable={onRemoveUnavailable}
            subTotal={subTotal}
            subTotalBeforeDiscounts={subTotalBeforeDiscounts}
            totalDiscount={bagDiscount > 0 ? formatPrice(bagDiscount) : null}
            tooltipText={tooltipText}
            isOverLimitForOrder={isOverLimitForOrder}
            unavailableItems={unavailableItems}
            caloriesLegal={caloriesLegal}
            handleCondimentsWindow={handleCondimentsWindow}
            warningMessage={warningMessage}
            isOfferApplicable={isOfferApplicable}
            isDiscountLoading={isDiscountLoading}
        />
    );
};

export default BagContentContainer;
