import React, { FC } from 'react';
import classnames from 'classnames';
import Icon from '../../atoms/BrandIcon';
import { Asset } from 'contentful';

import styles from './index.module.css';
import ContentfulImage from '../../atoms/ContentfulImage';

interface INotFoundProps {
    heading?: string;
    headingTag?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
    body?: string;
    icon?: Asset | JSX.Element;
    className?: string;
    iconClassName?: string;
    headerClassName?: string;
    paragraphClassName?: string;
}

interface IIconByDefaultProps {
    className?: string;
}
const IconByDefault: FC<IIconByDefaultProps> = ({ className }) => {
    return (
        <div className={classnames(styles.icons, className)}>
            <Icon className={classnames(styles.icon, styles.iconBan)} icon="info-ban" />
            <Icon className={classnames(styles.icon, styles.iconCow)} icon="animals-cow" />
        </div>
    );
};

const isContenfulAsset = (icon: Asset | JSX.Element): icon is Asset => 'fields' in icon;

interface INotFoundIconProps {
    icon?: Asset | JSX.Element;
    className?: string;
}

const NotFoundIcon: FC<INotFoundIconProps> = ({ className, icon }) => {
    if (!icon) {
        return <IconByDefault className={className} />;
    }

    if (isContenfulAsset(icon)) {
        return <ContentfulImage asset={icon} className={classnames(styles.icons, className)} />;
    }

    return icon;
};

const NotFound = (props: INotFoundProps): JSX.Element => {
    const {
        icon,
        heading,
        headingTag = 'h3',
        className,
        iconClassName,
        body,
        headerClassName,
        paragraphClassName,
    } = props;

    return (
        <div className={classnames(styles.container, className)}>
            <NotFoundIcon icon={icon} className={classnames({ [styles.iconsWithHeading]: !!heading }, iconClassName)} />
            {heading &&
                React.createElement(
                    headingTag,
                    { className: classnames(styles.header, 't-header-card-title', headerClassName) },
                    heading
                )}
            <p className={classnames(styles.text, 't-paragraph', paragraphClassName)}>{body}</p>
        </div>
    );
};

export default NotFound;
