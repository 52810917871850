import React, { memo } from 'react';
import MuiTextField, { TextFieldProps } from '@material-ui/core/TextField/TextField';

import styles from './TextField.module.css';

type ITextField = TextFieldProps & {
    dataTestId?: string;
};

const TextField = ({
    value,
    error,
    disabled,
    className,
    helperText,
    InputProps,
    dataTestId,
    placeholder,
    onChange,
    onKeyPress,
}: ITextField) => (
    <MuiTextField
        placeholder={placeholder}
        variant="outlined"
        disabled={disabled}
        className={className}
        helperText={!disabled && helperText}
        error={error}
        inputProps={{ 'data-testid': dataTestId }}
        onChange={onChange}
        onKeyPress={onKeyPress}
        value={value}
        FormHelperTextProps={{
            classes: {
                root: styles.helperText,
                error: styles.helperError,
            },
        }}
        InputProps={{
            classes: {
                root: styles.root,
                notchedOutline: styles.fieldset,
                adornedEnd: styles.endAdornment,
                input: styles.input,
                disabled: styles.disabled,
                error: styles.error,
            },
            ...InputProps,
        }}
    />
);

export default memo(TextField);
