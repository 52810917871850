/**@namespace useApplyPromocodeService*/

import { useMemo } from 'react';
import { useIDToken } from './useIDToken';
import { authorizationHeaderBuilder } from '../helpers/accountHelper';
import ApplyPromocodeService from '../services/customerService/applyPromocode';

/**
 * Hook for providing an instance of the ApplyPromocodeService class
 * @method useApplyPromocodeService
 * @added 2023-03-24
 * @version 1.0.0
 * @author Pavel Shpakovich <pavel.shpakovich@inspirebrands.com>
 * @returns {ApplyPromocodeService} - an instance of the ApplyPromocodeService class
 * @memberOf useApplyPromocodeService
 * @example
 * const applyPromocodeService = useApplyPromocodeService();
 */
export const useApplyPromocodeService = (): ApplyPromocodeService => {
    const { idToken } = useIDToken();

    const applyPromocodeService = useMemo(() => {
        return new ApplyPromocodeService(authorizationHeaderBuilder(idToken));
    }, [idToken]);

    return applyPromocodeService;
};
