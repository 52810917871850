import { useEffect, useState } from 'react';
import { getCheapestPriceFromProduct } from '../../../../lib/domainProduct';
import { useBag, useOrderLocation, usePdp } from '../../../../redux/hooks';
import { useAllCondiments, useDomainProducts, useDrinkCarrierCondiment } from '../../../../redux/hooks/domainMenu';
import { IDisplayProduct } from '../../../../redux/types';

interface IUseBagCondiments {
    condimentsToShow: IDisplayProduct[];
    isLimitReached: boolean;
    condimentsLimitMessage: string;
    actions: {
        handleCondimentQuantity: (id: string, quantity: number) => void;
        onSaveCondiments: () => void;
    };
}

export const useBagCondiments = (): IUseBagCondiments => {
    const allCondiments = useAllCondiments();
    const drinkCarrier = useDrinkCarrierCondiment();
    const { currentLocation, condimentsLimit } = useOrderLocation();
    const domainCondiments = useDomainProducts(
        allCondiments.map(({ displayProductDetails: { productId } }) => productId)
    );
    const {
        bagEntries,
        actions: { putCondimentsToBag },
    } = useBag();
    const {
        actions: { onCondimentChange },
    } = usePdp();

    const [isLimitReached, setIsLimitReached] = useState<boolean>(false);
    const [condiments, setCondiments] = useState<IDisplayProduct[]>(() => {
        return (
            allCondiments.map((c) => {
                const bagCondiment = bagEntries.find((entry) => entry.productId === c.displayProductDetails.productId);
                if (!bagCondiment) return c;
                return {
                    ...c,
                    displayProductDetails: {
                        ...c.displayProductDetails,
                        quantity: bagCondiment.quantity,
                    },
                };
            }) || []
        );
    });

    //No need to show drink carrier
    const condimentsToShow = condiments.filter(
        ({ displayProductDetails: { productId } }) => productId !== drinkCarrier?.displayProductDetails?.productId
    );

    const condimentsQuantity = condiments.reduce((prev, acc) => {
        return prev + acc.displayProductDetails.quantity;
    }, 0);

    useEffect(() => {
        setIsLimitReached(condimentsQuantity >= condimentsLimit);
    }, [condimentsQuantity, condimentsLimit]);

    const handleCondimentQuantity = (id: string, quantity: number): void => {
        setCondiments((condiments) =>
            condiments.map((c) => {
                if (c.displayProductDetails.productId !== id) return c;
                return {
                    ...c,
                    displayProductDetails: {
                        ...c.displayProductDetails,
                        quantity,
                    },
                };
            })
        );
    };

    const onSaveCondiments = (): void => {
        const condimentsById = condiments.reduce((prev, acc) => {
            const { productId, quantity } = acc.displayProductDetails;
            return {
                ...prev,
                [productId]: {
                    productId,
                    quantity,
                    price: getCheapestPriceFromProduct(domainCondiments?.[productId], currentLocation),
                },
            };
        }, {});
        condiments.forEach(({ displayProductDetails: { productId, quantity, productGroupId } }) => {
            if (quantity === 0) return;
            onCondimentChange({
                modifierGroupId: productGroupId,
                modifierId: productId,
                quantity,
            });
        });
        putCondimentsToBag(condimentsById);
    };

    return {
        condimentsToShow,
        isLimitReached,
        condimentsLimitMessage: `You've reached the condiment limit (${condimentsLimit}).`,
        actions: {
            handleCondimentQuantity,
            onSaveCondiments,
        },
    };
};
