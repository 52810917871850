/**@namespace usePromocode*/

import { useState } from 'react';
import { TErrorCodeModel } from '../../@generated/webExpApi/models/TErrorCodeModel';
import { TErrorMessageModel } from '../../@generated/webExpApi/models/TErrorMessageModel';
import { TOffersUnionModel } from '../../@generated/webExpApi/models/TOffersUnionModel';
import { useBag, useOrderLocation, useRewards } from '../../redux/hooks';
import { useApplyPromocodeService } from './useApplyPromocodeService';

interface IPromocodeErorr {
    isValidationError: boolean;
    errorMessage: string;
}

interface IUsePromocode {
    error: IPromocodeErorr;
    isApplied: boolean;
    isLoading: boolean;
    resetPromocode: () => void;
    applyPromocode: (promocode: string) => Promise<void>;
}

/**
 * Hook for managing the promo codes related functionality
 * @method usePromocode
 * @added 2023-03-23
 * @version 1.0.0
 * @author Pavel Shpakovich <pavel.shpakovich@inspirebrands.com>
 * @returns {IUsePromocode} - an object includes function to send apply promo code request, function reset current state and current state values
 * @memberOf usePromocode
 * @example
 * const { applyPromocode, resetPromocode, error, isLoading, isApplied } = usePromocode();
 */
export const usePromocode = (): IUsePromocode => {
    const applyPromocodeService = useApplyPromocodeService();

    const {
        actions: { addDealToBag },
    } = useBag();

    const {
        appliedPromocode,
        isApplyPromocodeLoading: isLoading,
        actions: { setAppliedPromocode, setApplyPromocodeLoading },
    } = useRewards();

    const { currentLocation } = useOrderLocation();

    const { timezone: timezoneId, id: locationId } = currentLocation || {};

    const [error, setError] = useState<IPromocodeErorr | null>(null);

    const isApplied = !!appliedPromocode;

    const applyPromocode = async (promocode: string): Promise<void> => {
        setApplyPromocodeLoading(true);
        setError(null);

        try {
            const response = await applyPromocodeService.applyPromocode({ promocode, timezoneId, locationId });
            setAppliedPromocode((response as unknown) as TOffersUnionModel);
            addDealToBag({ id: response.id });
        } catch (e) {
            let errorMessage = e.message;
            const isValidationError = e.code === TErrorCodeModel.PromocodeValidation;
            if (isValidationError) {
                errorMessage = TErrorMessageModel.PromocodeIsNotValid;
            }
            setError({ errorMessage, isValidationError });
        } finally {
            setApplyPromocodeLoading(false);
        }
    };

    const resetPromocode = () => {
        setApplyPromocodeLoading(false);
        setError(null);
    };

    return { applyPromocode, resetPromocode, error, isLoading, isApplied };
};
