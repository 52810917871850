import React from 'react';
import classnames from 'classnames';
import { SHOULD_SHOW_FREE_PRICE, TYPOGRAPHY_CLASS } from './constants';

import styles from './bagItemPrice.module.css';
import { formatPrice } from '../../../lib/domainProduct';
import Loader from '../../atoms/Loader';

interface IBagItemPrice {
    entryPrice: number;
    isDiscountLoading?: boolean;
    discountProductAmount?: number;
}

const BagItemPrice = (props: IBagItemPrice) => {
    const { entryPrice, isDiscountLoading, discountProductAmount } = props;
    const shouldShowFreePrice = entryPrice === 0 && SHOULD_SHOW_FREE_PRICE;
    const hasDiscountedPrice = discountProductAmount > 0;
    const discountedPrice = entryPrice - discountProductAmount;

    const price = (
        <>
            {hasDiscountedPrice && (
                <span className={styles.price} data-testid="bag-item-discounted-price">
                    {discountedPrice > 0 ? formatPrice(discountedPrice) : 'Free'}
                </span>
            )}
            <span
                className={classnames({ [styles.strikethroughPrice]: hasDiscountedPrice })}
                data-testid="bag-item-price"
            >
                {shouldShowFreePrice ? 'Free' : formatPrice(entryPrice)}
            </span>
        </>
    );

    return (
        <div
            className={classnames(TYPOGRAPHY_CLASS.NAME, styles.wrapper, styles.bagItemPrice, {
                [styles.bagItemPriceHighlighted]: shouldShowFreePrice,
            })}
        >
            {isDiscountLoading ? <Loader className={styles.loader} size={16} /> : price}
        </div>
    );
};

export default BagItemPrice;
