import React from 'react';
import classnames from 'classnames';
import Tooltip, { TooltipProps } from '@material-ui/core/Tooltip';

import styles from './tooltip.module.css';

export interface IInspireTooltipProps {
    tooltipClassName?: string;
    arrowClassName?: string;
    theme?: 'dark' | 'light' | 'primary';
    disablePortal?: boolean;
}

/**
 *
 * Demos:
 *
 * - [Tooltips](https://material-ui.com/components/tooltips/)
 *
 * API:
 *
 * - [Tooltip API](https://material-ui.com/api/tooltip/)
 */

const InspireTooltip = ({
    tooltipClassName,
    arrowClassName,
    theme = 'dark',
    disablePortal,
    ...props
}: IInspireTooltipProps & TooltipProps): JSX.Element => {
    return (
        <Tooltip
            PopperProps={{
                disablePortal: disablePortal,
            }}
            classes={{
                tooltip: classnames(
                    tooltipClassName,
                    styles.container,
                    't-paragraph-small-strong',
                    { [styles.light]: theme === 'light' },
                    { [styles.dark]: theme === 'dark' },
                    { [styles.primary]: theme === 'primary' }
                ),
                arrow: classnames(
                    arrowClassName,
                    styles.arrow,
                    { [styles.lightArrow]: theme === 'light' },
                    { [styles.darkArrow]: theme === 'dark' },
                    { [styles.primaryArrow]: theme === 'primary' }
                ),
            }}
            {...props}
        />
    );
};

export default InspireTooltip;
