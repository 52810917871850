import React from 'react';
import classNames from 'classnames';

import styles from './index.module.css';
import InspireTooltip from '../Tooltip';
import { SHOW_MOBILE_LABEL, TEXT_DISCOUNT, TEXT_PROMO_CODE, TYPOGRAPHY_CLASS } from './constants';
import { FeeItemModel } from '../../../@generated/webExpApi/models/FeeItemModel';
import Loader from '../Loader';
import { useScreen } from '../../../common/hooks/useScreen';
import { CONSTANTS } from '../../../constants/en/commons';

export interface FormattedFee extends Omit<FeeItemModel, 'amount'> {
    amount: string;
    name: string;
    legalText?: string;
}

interface IReviewOrderTotalProps {
    subtotal?: number | string;
    totalDiscount?: number | string;
    totalPromoCodeDiscount?: number | string;
    totalPrice?: number | string;
    totalTax?: number | string;
    certificateDiscount?: number | string;
    totalTaxLabel?: string;
    totalTip?: string;
    tooltipText?: string | JSX.Element;
    additionalFees?: number | string;
    formattedFees?: Array<FormattedFee>;
    isOverLimitForOrder?: boolean;
    tipPercentage?: number;
    cardNumber?: string;
    showCardNumber?: boolean;
    additionalDiscountFee?: FormattedFee;
    isDiscountLoading?: boolean;
    paymentMethod?: string;
    classes?: typeof TYPOGRAPHY_CLASS;
}

const ReviewOrderTotal = (props: IReviewOrderTotalProps): JSX.Element => {
    const {
        subtotal,
        paymentMethod,
        totalDiscount,
        totalPromoCodeDiscount,
        totalPrice,
        totalTax,
        certificateDiscount,
        totalTaxLabel,
        isOverLimitForOrder,
        tooltipText,
        additionalFees,
        formattedFees,
        totalTip,
        tipPercentage,
        cardNumber,
        showCardNumber,
        additionalDiscountFee,
        isDiscountLoading,
        classes,
    } = props;
    const { REWARD_CERTIFICATE, PAYMENT, CARD_NUMBER, SUB_TOTAL, TOTAL } = CONSTANTS;
    const { isDesktop } = useScreen();
    const TYPOGRAPHY_CLASSES = classes || TYPOGRAPHY_CLASS;
    const totalItemClassName = isDesktop ? TYPOGRAPHY_CLASSES.TOTAL_ITEM : TYPOGRAPHY_CLASSES.TOTAL_ITEM_MOBILE; //TODO: should be removed after design changes

    const maskedCardNumber = cardNumber && showCardNumber ? `${'*'.repeat(12)}${cardNumber}` : null;

    return (
        <div className={styles.reviewOrder}>
            {paymentMethod && (
                <div className={styles.reviewOrderItem}>
                    <div className={classNames(styles.totalItem, totalItemClassName)}>{PAYMENT}</div>
                    <div className={classNames(styles.totalItem, totalItemClassName)}>{paymentMethod}</div>
                </div>
            )}
            {certificateDiscount && (
                <div className={styles.reviewOrderItem}>
                    <div className={classNames(styles.totalItem, styles.greenColor, totalItemClassName)}>
                        {REWARD_CERTIFICATE}
                    </div>
                    <div className={classNames(styles.totalItem, styles.greenColor, styles.price, totalItemClassName)}>
                        {certificateDiscount}
                    </div>
                </div>
            )}
            {subtotal && (
                <div className={styles.reviewOrderItem}>
                    <div className={classNames(styles.totalItem, totalItemClassName)}>{SUB_TOTAL}</div>
                    <div className={styles.subtotalContainer}>
                        <InspireTooltip
                            tooltipClassName={classNames(styles.tooltip, { [styles.tooltipLabel]: SHOW_MOBILE_LABEL })}
                            open={isOverLimitForOrder && !!tooltipText}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            title={tooltipText || ''}
                            placement="left"
                            theme="primary"
                            arrow
                            PopperProps={{
                                disablePortal: true,
                                modifiers: {
                                    flip: {
                                        enabled: false,
                                    },
                                    preventOverflow: {
                                        enabled: false,
                                    },
                                },
                            }}
                        >
                            <div className={classNames(styles.totalItem, styles.price, totalItemClassName)}>
                                {!isDiscountLoading ? subtotal : <Loader size={16} />}
                            </div>
                        </InspireTooltip>
                    </div>
                </div>
            )}
            {totalDiscount && !isDiscountLoading && (
                <div className={styles.reviewOrderItem}>
                    <div
                        className={classNames(
                            styles.totalItem,
                            styles.discount,
                            styles.discountFont,
                            totalItemClassName
                        )}
                        data-testid="discounts-applied-text"
                    >
                        {TEXT_DISCOUNT}
                    </div>
                    <div
                        className={classNames(
                            styles.totalItem,
                            styles.discount,
                            styles.price,
                            styles.discountFont,
                            totalItemClassName
                        )}
                        data-testid="discounts-applied-price"
                    >
                        <span className={styles.discountMinus}>-</span>
                        {totalDiscount}
                    </div>
                </div>
            )}
            {totalPromoCodeDiscount && (
                <div className={styles.reviewOrderItem}>
                    <div
                        className={classNames(
                            styles.totalItem,
                            styles.discount,
                            styles.discountFont,
                            totalItemClassName
                        )}
                        data-testid="promo-code-applied-text"
                    >
                        {TEXT_PROMO_CODE}
                    </div>
                    <div
                        className={classNames(
                            styles.totalItem,
                            styles.discount,
                            styles.price,
                            styles.discountFont,
                            totalItemClassName
                        )}
                        data-testid="promo-code-applied-price"
                    >
                        <span className={styles.discountMinus}>-</span>
                        {totalPromoCodeDiscount}
                    </div>
                </div>
            )}
            {additionalDiscountFee && (
                <div className={styles.reviewOrderItem}>
                    <div className={classNames(styles.totalItem, styles.discount, totalItemClassName)}>
                        {additionalDiscountFee.legalText}
                    </div>
                    <div className={classNames(styles.totalItem, styles.discount, styles.price, totalItemClassName)}>
                        {additionalDiscountFee.amount}
                    </div>
                </div>
            )}
            {formattedFees &&
                formattedFees.map((fee, i) => {
                    const asterisksAmount =
                        fee.legalText && formattedFees.slice(0, i).filter((fee) => fee.legalText).length + 1;

                    return (
                        <div className={styles.reviewOrderItem} key={fee.type}>
                            <div
                                className={classNames(
                                    styles.totalItem,
                                    totalItemClassName,
                                    fee.amount === '$0.00' ? styles.discount : '',
                                    't-paragraph-small'
                                )}
                            >
                                {fee.name} {'*'.repeat(asterisksAmount)}
                            </div>
                            <div
                                className={classNames(
                                    styles.totalItem,
                                    totalItemClassName,
                                    fee.amount === '$0.00' ? styles.discount : '',
                                    styles.price,
                                    't-paragraph-small'
                                )}
                            >
                                {fee.amount}
                            </div>
                        </div>
                    );
                })}
            {additionalFees && (
                <div className={styles.reviewOrderItem}>
                    <div className={classNames(styles.totalItem, totalItemClassName)}>Additional fees</div>
                    <div className={classNames(styles.totalItem, styles.price, totalItemClassName)}>
                        {additionalFees}
                    </div>
                </div>
            )}
            {totalTip && (
                <div className={styles.reviewOrderItem}>
                    <div className={classNames(styles.totalItem, totalItemClassName)}>{`Tip${
                        tipPercentage ? ` (${tipPercentage}%)` : ''
                    }`}</div>
                    <div className={classNames(styles.totalItem, styles.price, totalItemClassName)}>{totalTip}</div>
                </div>
            )}
            {totalTax && (
                <div className={styles.reviewOrderItem}>
                    <div className={classNames(styles.totalItem, totalItemClassName)}>{totalTaxLabel || 'Tax'}</div>
                    <div className={classNames(styles.totalItem, styles.price, totalItemClassName)}>
                        {!isDiscountLoading ? totalTax : <Loader size={16} />}
                    </div>
                </div>
            )}
            {totalPrice && (
                <div className={styles.reviewOrderItem}>
                    <div className={classNames(styles.totalItem, styles.totalLine, TYPOGRAPHY_CLASSES.TOTAL_LINE)}>
                        {TOTAL}
                    </div>
                    <div
                        className={classNames(
                            styles.totalItem,
                            styles.totalLine,
                            styles.price,
                            TYPOGRAPHY_CLASSES.TOTAL_LINE
                        )}
                    >
                        {!isDiscountLoading ? totalPrice : <Loader size={16} />}
                    </div>
                </div>
            )}
            {formattedFees &&
                formattedFees
                    .filter((fee) => fee.legalText)
                    .map((fee, i) => (
                        <div className={classNames(styles.legal, 't-paragraph-hint')} key={fee.legalText}>
                            {'*'.repeat(i + 1)} {fee.legalText}
                        </div>
                    ))}
            {maskedCardNumber && (
                <div className={styles.reviewOrderItem}>
                    <div className={classNames(styles.totalItem, styles.cardNumber, 't-paragraph')}>{CARD_NUMBER}</div>
                    <div className={classNames(styles.totalItem, styles.cardNumber, styles.price, 't-paragraph')}>
                        {maskedCardNumber}
                    </div>
                </div>
            )}
        </div>
    );
};

export default ReviewOrderTotal;
