import { TServiceTypeModel } from '../../../@generated/webExpApi/models';
import { TimeSlotValues } from './index';
import { SIZE_PLACEMENTS } from './types';

export const typeButton = 'secondary';

export const TYPOGRAPHY_CLASS = {
    NAME: 't-subheader-small',
    PRICE_TEXT: 't-body-price',
};

export const PLUS_MINUS_ICONS_ACTIVE_VARIANT = 'colorful';

export const SHOW_NUMBER_ITEMS = true;

export const getTimeValues = (timeslots: TimeSlotValues) => {
    if (
        timeslots.arbysTimeslotValues &&
        timeslots.arbysTimeslotValues.length > 0 &&
        timeslots.arbysTimeslotValues[0].timeRange.length > 0
    ) {
        return timeslots.arbysTimeslotValues[0].timeRange[0];
    }
    return null;
};

export const CLOSE_BAG_TITLE = 'CLOSE BAG';
export const SHOW_DIVIDER = false;
export const SELECT_A_LOCATION_TITLE = 'SELECT A LOCATION';
export const CHANGE_LOCATION_TITLE = 'CHANGE LOCATION';
export const START_AN_ORDER_TITLE = 'START AN ORDER';
export const EXTEND_ICON = '';
export const EXTEND_HEADER = '';
export const EXTEND_PARAGRAPH = '';
export const CHECKOUT_BUTTON_TEXT = 'CHECKOUT NOW';
export const ADD_MORE_ITEMS_TEXT = 'ADD MORE ITEMS';
export const ITEM_MODIFY_TEXT = 'MODIFY';
export const ITEM_REMOVE_TEXT = 'REMOVE';
export const SHOW_CALORIES_LEGAL = false;
export const SHOW_CALORIES_PRODUCT = false;
export const SHOULD_SHOW_DISCOUNT_NAME = false;
export const SHOW_OVER_LIMIT_SIMPLE_TEXT = false;

export const ADD_REMOVED_ITEM_TEXT = 'ADD THIS ITEM BACK TO YOUR BAG';
export const REMOVED_ITEM_TEXT_PART = 'was removed!';

export const ARE_CONDIMENTS_ENABLED = false;
export const SHOULD_SHOW_FREE_PRICE = false;

export const SHOULD_SHOW_DISCOUNT_ON_BAG = true;
export const SHOULD_NOT_REMOVE_DEAL_AFTER_REMOVE_UNAVAILABLE_ITEMS = true;
export const SHOULD_ALWAYS_SHOW_USER_DEALS = false;
export const APPLIED_DEAL_TEXT = 'applied deal';
export const APPLIED_PROMO_CODE_TEXT = '';

export const CHECK_ALLOWED_PER_PRODUCT = false;

export const IS_INCLUDE_SOURCE_PRODUCT_ID = false;

export const SERVICE_TYPE = TServiceTypeModel.Pickup;
export const ALLOW_LOGIN_FROM_BAG = false;

export const SIZE_PLACEMENT: SIZE_PLACEMENTS = SIZE_PLACEMENTS['AFTER_TITLE'];

export const OFFER_HEADER_CLASS = 'truncate-at-2';

export const USE_GTM_RE_ADD_ITEM_TO_CART = false;

export const SHOW_DISCOUNT_BANNER = false;

export const SHOW_EXPIRED_REWARD_WARNING = false;

export const USE_GTM_REMOVE_ITEM_FROM_CART = false;
export const SHOULD_REMOVE_ENTIRE_PRODUCT_IF_MODIFIERS_UNAVAILABLE = false;
