import React, { FC } from 'react';
import classnames from 'classnames';
import Modal from '@material-ui/core/Modal';
import Icon from '../../atoms/BrandIcon';
import { InspireButton } from '../../atoms/button';

import styles from './dealWarningModal.module.css';
import { CONSTANTS } from '../../../constants/en/commons';
import { mergeDynamicValueAndComponent } from '../../../common/helpers/mergeStringAndComponent';

export interface IDealWarningModalProps {
    open: boolean;
    dealName: string;
    isOfferApplicable: boolean;
    isOfferExpired: boolean;
    isPromoCode?: boolean;
    onClose?: () => void;
    onAddMoreItemsClick?: () => void;
    onCheckoutClick?: () => void;
}

const DealWarningModal: FC<IDealWarningModalProps> = ({
    open,
    dealName,
    onClose,
    onAddMoreItemsClick,
    onCheckoutClick,
    isOfferApplicable,
}) => {
    const { OFFER_DESCRIPTION_APPLICABLE, OFFER_DESCRIPTION_NON_APPLICABLE, ADD_MORE_ITEMS, GO_BACK } = CONSTANTS;
    const applicableText = (isApplicable, dealName) => {
        const textApplicable = isApplicable ? OFFER_DESCRIPTION_APPLICABLE : OFFER_DESCRIPTION_NON_APPLICABLE;
        return mergeDynamicValueAndComponent({
            classname: classnames('t-paragraph', styles.description),
            stringValue: textApplicable,
            dynamicElements: {
                '{dealName}': <span className={styles.dealName}>{dealName}</span>,
            },
        });
    };

    return (
        <Modal open={open} className={styles.modal} onClose={onClose}>
            <div className={styles.content}>
                <button
                    aria-label="Close Icon"
                    className={styles.closeButton}
                    onClick={onClose}
                    data-testid="deal-warning-modal-close"
                >
                    <Icon icon="action-close" size="m" className={styles.closeIcon} />
                </button>
                <Icon icon="info-error" size="xl" className={styles.warnIcon} />
                <span className={classnames('t-header-h3', styles.title)}>Wait!</span>
                {applicableText(isOfferApplicable, dealName)}
                <div className={styles.buttons}>
                    {isOfferApplicable ? (
                        <InspireButton
                            type="secondary"
                            onClick={onAddMoreItemsClick}
                            text={ADD_MORE_ITEMS}
                            className={styles.button}
                            data-testid="deal-warning-modal-add-more"
                        />
                    ) : (
                        <InspireButton
                            type="secondary"
                            onClick={onClose}
                            text={GO_BACK}
                            className={styles.button}
                            data-testid="deal-warning-modal-go-back"
                        />
                    )}
                    <InspireButton
                        onClick={onCheckoutClick}
                        text="Checkout Now"
                        className={styles.button}
                        data-testid="deal-warning-modal-checkout"
                    />
                </div>
            </div>
        </Modal>
    );
};

export default DealWarningModal;
