import React, { FC, useState } from 'react';
import classnames from 'classnames';
import Icon from '../../../atoms/BrandIcon';
import { InspireButton } from '../../../atoms/button';
import { CondimentItem } from '../../../atoms/condimentItem/condimentItem';

import { useBagCondiments } from './useBagCondiments';
import { useGlobalProps } from '../../../../redux/hooks';
import { useSafeSetTimeout } from '../../../../common/hooks/useSafeSetTimeout';

import styles from './bagCondiments.module.css';

interface IBagCondiments {
    handleCondimentsWindow: (payload: boolean) => void;
}

export const BagCondiments: FC<IBagCondiments> = ({ handleCondimentsWindow }) => {
    const [limitReachedId, setLimitReachedId] = useState(null);
    const { modifierItemsById } = useGlobalProps();
    const {
        condimentsToShow,
        isLimitReached,
        condimentsLimitMessage,
        actions: { handleCondimentQuantity, onSaveCondiments },
    } = useBagCondiments();
    const safeSetTimeout = useSafeSetTimeout();

    const handleTooltip = (id: string) => {
        if (isLimitReached) {
            setLimitReachedId(id);
            safeSetTimeout(() => setLimitReachedId(null), 2000);
        } else {
            setLimitReachedId(null);
        }
    };

    const handleCondimentPlus = (id: string, quantity: number): void => {
        if (isLimitReached) {
            handleTooltip(id);
            return;
        }
        handleCondimentQuantity(id, quantity);
    };
    const handleCondimentMinus = (id: string, quantity: number): void => {
        if (quantity < 0) return;
        handleCondimentQuantity(id, quantity);
    };

    const onCloseWindow = () => handleCondimentsWindow(false);

    const onSaveAndClose = () => {
        onSaveCondiments();
        onCloseWindow();
    };

    return (
        <div className={styles.container}>
            <button aria-label="Close Icon" className={styles.closeButton} onClick={onCloseWindow}>
                <Icon icon="action-close" size="s" variant="dark" />
            </button>
            <section className={styles.header}>
                <span className={classnames('t-subheader t-stylized', styles.subtitle)}>Choose</span>
                <h1 className={classnames('t-header-h1', styles.title)}>Your Condiments</h1>
            </section>
            <section className={styles.content}>
                <h3 className={classnames('t-header-h3', styles.contentTitle)}>Condiments</h3>
                <div className={styles.condimentsList}>
                    {condimentsToShow?.map(
                        ({ displayProductDetails: { productId, displayName, calories, quantity } }) => {
                            const image = (modifierItemsById[productId] || modifierItemsById['default'])?.fields.image;
                            return (
                                <CondimentItem
                                    key={productId}
                                    image={image}
                                    displayName={displayName}
                                    calories={calories}
                                    productId={productId}
                                    tooltipMessage={condimentsLimitMessage}
                                    limitReachedItemId={limitReachedId}
                                    quantity={quantity}
                                    isCondimentsLimitReached={isLimitReached}
                                    handleCondimentMinus={handleCondimentMinus}
                                    handleCondimentPlus={handleCondimentPlus}
                                />
                            );
                        }
                    )}
                </div>
            </section>
            <div className={styles.buttonContainer}>
                <InspireButton
                    className={styles.actionButton}
                    type="primary"
                    onClick={onSaveAndClose}
                    text="Save and Close"
                />
            </div>
        </div>
    );
};
