import React, { useState } from 'react';
import { ISuggestedDeal } from '../../../../redux/hooks/useSuggestedDeal';
import { SuggestProductModalContainer } from '../../../organisms/suggestProductModalContainer/suggestProductModalContainer';
import Icon from '../../../atoms/BrandIcon';

import styles from './bagItemSuggestedDeal.module.css';
import { ICON_VARIANT, SELECT_PRODUCT_TITLE } from './bagItemSuggestedDeal.constants';

interface IProps {
    suggestedDeal: ISuggestedDeal;
}

const BagItemSuggestedDeal: React.FC<IProps> = ({ suggestedDeal }) => {
    const { partiallyMatchOffer: offer, remainingBuyIds, remainingGetIds, remainingEligibleIds } = suggestedDeal;

    const [modalOpened, setModalOpen] = useState<boolean>(false);
    const handleOpenModal = () => {
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    return (
        <div className={styles.container} data-testid="bag-item-suggested-deal">
            <span className={styles.offerName} data-testid="bag-item-suggested-deal-name">
                {offer.name}
            </span>
            <div
                className={styles.selectProductsContainer}
                data-testid="bag-item-suggested-deal-select-products"
                onClick={handleOpenModal}
            >
                <Icon icon="action-add" size="xs" variant={ICON_VARIANT} />
                <span className={styles.selectProducts}>{SELECT_PRODUCT_TITLE}</span>
            </div>
            {modalOpened && (
                <SuggestProductModalContainer
                    offer={offer}
                    remainingBuyIds={remainingBuyIds}
                    remainingGetIds={remainingGetIds}
                    remainingEligibleIds={remainingEligibleIds}
                    isOpen={modalOpened}
                    onClose={handleCloseModal}
                />
            )}
        </div>
    );
};

export default BagItemSuggestedDeal;
