import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { useGlobalProps } from '../../../../redux/hooks';
import { IDisplayProduct } from '../../../../redux/types';
import { useSafeSetTimeout } from '../../../../common/hooks/useSafeSetTimeout';
import { useBagDrinkCarrier } from './useBagDrinkCarrier';
import { CondimentItem } from '../../../atoms/condimentItem/condimentItem';
import styles from './bagDrinkCarrier.module.css';

interface IBagDrinkCarrier {
    drinkCarrier: IDisplayProduct;
    isCondimentsLimitReached: boolean;
}

export const BagDrinkCarrier = ({ drinkCarrier, isCondimentsLimitReached }: IBagDrinkCarrier) => {
    const [limitReachedId, setLimitReachedId] = useState(null);
    const { modifierItemsById } = useGlobalProps();
    const safeSetTimeout = useSafeSetTimeout();
    const {
        condiment,
        condimentLimitMessage,
        actions: { handleQuantity },
    } = useBagDrinkCarrier(drinkCarrier);

    const { productId, displayName, calories, quantity } = condiment.displayProductDetails;

    const handleTooltip = (id: string, isLimitReached: boolean) => {
        if (isLimitReached) {
            setLimitReachedId(id);
            safeSetTimeout(() => setLimitReachedId(null), 2000);
        } else {
            setLimitReachedId(null);
        }
    };

    useEffect(() => {
        handleTooltip(productId, isCondimentsLimitReached);
    }, [isCondimentsLimitReached, productId]);

    const handlePlus = (id: string, quantity: number): void => {
        if (isCondimentsLimitReached) {
            handleTooltip(id, isCondimentsLimitReached);
            return;
        }
        handleQuantity(quantity);
    };
    const handleMinus = (_, quantity: number): void => {
        if (quantity < 0) return;
        handleQuantity(quantity);
    };
    const image = (modifierItemsById[productId] || modifierItemsById['default'])?.fields.image;

    return (
        <section className={styles.container}>
            <h1 className={classnames('t-header-h1', styles.title)}>Need Carriers?</h1>
            <div className={styles.content}>
                <CondimentItem
                    key={productId}
                    image={image}
                    displayName={displayName}
                    calories={calories}
                    productId={productId}
                    limitReachedItemId={limitReachedId}
                    tooltipMessage={condimentLimitMessage}
                    quantity={quantity}
                    isCondimentsLimitReached={isCondimentsLimitReached}
                    handleCondimentMinus={handleMinus}
                    handleCondimentPlus={handlePlus}
                    isDrinkCarrier
                />
            </div>
        </section>
    );
};
