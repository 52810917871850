import React from 'react';
import { useBag, useOrderLocation, useRewards } from '../../../redux/hooks';

import UserDeals from './userDeals';

const UserDealsContainer = (): JSX.Element => {
    const { getApplicableOffers, appliedPromocode } = useRewards();
    const {
        actions,
        dealId,
        actions: { toggleIsOpen },
    } = useBag();
    const { isCurrentLocationOAAvailable, currentLocation } = useOrderLocation();
    const offers = getApplicableOffers(currentLocation?.id);

    const handleCloseBag = () => {
        toggleIsOpen({ isOpen: false });
    };

    const disabled = !!appliedPromocode;

    return (
        <UserDeals
            offers={offers}
            dealId={dealId}
            disabled={disabled}
            closeBag={handleCloseBag}
            onAddDealToBag={actions.addDealToBag}
            isCurrentLocationOAAvailable={isCurrentLocationOAAvailable}
        />
    );
};

export default UserDealsContainer;
