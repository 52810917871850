import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import classnames from 'classnames';

import { DiscountDetailsTypeModel, ItemModel } from '../../../@generated/webExpApi';
import { IProductFields, IProductModifierFields } from '../../../@generated/@types/contentful';
import {
    useDomainMenuSelectors,
    useDomainProduct,
    useDomainProducts,
    useProductItemGroup,
    useIsProductEditable,
    useSelectedModifiers,
    useTallyPriceAndCalories,
    useProductGroup,
} from '../../../redux/hooks/domainMenu';
import ContentfulImage from '../../atoms/ContentfulImage';

import styles from './item.module.css';

import ListModifiers from '../../atoms/ListModifiers';
import Icon from '../../atoms/BrandIcon';
import { useBag, useOrderLocation, usePdp } from '../../../redux/hooks';
import { formatPrice } from '../../../lib/domainProduct';
import InspireBadge from '../../atoms/Badge';

import {
    GTM_CHECKOUT_PRODUCT_QUANTITY_CHANGE,
    GTM_MODIFY_PRODUCT,
    GTM_MODIFY_IN_CART,
    GTM_REMOVE_FROM_CART,
    GTM_RESTORE_ITEM_TO_CART,
    GTM_RE_ADD_ITEM_TO_CART,
    GTM_REMOVE_FROM_CART_EVENT,
} from '../../../common/services/gtmService/constants';
import TextWithTrademark from '../../atoms/textWithTrademark';
import { getBagItemSizeLabel, removeUnavailableModifiers } from '../../../common/helpers/bagHelper';
import { useAppDispatch } from '../../../redux/store';
import { requestNavIntercept } from '../../../redux/navIntercept';
import { useTallyModifiers } from '../../../common/hooks/useTallyModifiers';
import { useProductHasOtherSizes } from '../../../common/hooks/useProductHasOtherSizes';
import { MAX_PRODUCT_QUANTITY, MIN_PRODUCT_QUANTITY } from '../../../common/constants/bag';

import { getChangedModifiers } from '../../../common/helpers/getChangedModifiers';
import {
    ADD_REMOVED_ITEM_TEXT,
    CHECK_ALLOWED_PER_PRODUCT,
    ITEM_MODIFY_TEXT,
    ITEM_REMOVE_TEXT,
    PLUS_MINUS_ICONS_ACTIVE_VARIANT,
    REMOVED_ITEM_TEXT_PART,
    SHOULD_SHOW_DISCOUNT_ON_BAG,
    SHOW_CALORIES_PRODUCT,
    TYPOGRAPHY_CLASS,
    SIZE_PLACEMENT,
    SHOULD_SHOW_DISCOUNT_NAME,
    USE_GTM_RE_ADD_ITEM_TO_CART,
    SHOW_DISCOUNT_BANNER,
    USE_GTM_REMOVE_ITEM_FROM_CART,
    SHOULD_REMOVE_ENTIRE_PRODUCT_IF_MODIFIERS_UNAVAILABLE,
} from './constants';
import { InspireLinkButton } from '../../atoms/link';
import NonexistentItem from './nonexistentItem/nonexistentItem';
import { ItemGroupEnum, ModifierGroupType } from '../../../redux/types';

import { InspireCmsEntry } from '../../../common/types';
import InspireTooltip from '../../atoms/Tooltip';
import { validateMaxItemAmount } from '../../../lib/getFeatureFlags';
import { useCheckIsCondiment } from '../../../common/hooks/useCheckIsCondiment';
import { useSafeSetTimeout } from '../../../common/hooks/useSafeSetTimeout';
import BagItemPrice from './bagItemPrice';
import Loader from '../../atoms/Loader';
import { ISuggestedDeal } from '../../../redux/hooks/useSuggestedDeal';
import BagItemSuggestedDeal from './bagItemSuggestedDeal';
import { GtmEventNames } from '../../../common/services/gtmService/types';
import { IBagLineItem } from '../../../redux/bag';
import { SHOW_COMBO_PRICE_PLUS } from '../checkout/reviewOrder/constants';

interface IBagItemProps {
    entry: IBagLineItem;
    markedAsRemoved: boolean;
    contentfulProduct: InspireCmsEntry<IProductFields> | InspireCmsEntry<IProductModifierFields>;
    entryPath?: { href: string; as: string };
    discountBanner?: string;
    tabIndex?: number;
    unavailableModifiers?: string[];
    unavailableChildItems?: string[];
    isDefaultModifiersUnavailable?: boolean;
    unavailableSubModifiers?: string[];
    isUnavailableItem?: boolean;
    suggestedDeal?: ISuggestedDeal;
    isCondimentsLimitReached?: boolean;
    condimentsLimit?: number;
    hasSimpleOffer?: boolean;
}

interface IBagItemBlockProps {
    entry: IBagLineItem;
    contentfulProduct: InspireCmsEntry<IProductFields> | InspireCmsEntry<IProductModifierFields>;
    entryPath?: { href: string; as: string };
    domainProduct: ItemModel;
    discountBanner?: string;
    tabIndex?: number;
    unavailableModifiers?: string[];
    isDefaultModifiersUnavailable?: boolean;
    unavailableSubModifiers?: string[];
    unavailableChildItems?: string[];
    isUnavailableItem: boolean;
    isCondimentsLimitReached?: boolean;
    condimentsLimit?: number;
}

interface IRemovedItemProps {
    entry: IBagLineItem;
    domainProduct: ItemModel;
}

interface IBagItemDetailsProps {
    entry: IBagLineItem;
    entryPrice: number;
    domainProduct: ItemModel;
    unavailableModifiers?: string[];
    isDefaultModifiersUnavailable?: boolean;
    unavailableSubModifiers?: string[];
    unavailableChildItems?: string[];
    isUnavailableItem: boolean;
    calories?: number;
    isDiscountLoading?: boolean;
}

export interface ITallyItemProduct {
    product: ItemModel;
    item: IBagLineItem;
}

export interface IBagDealItem {
    name: string;
    id: string;
    endDate?: Date;
    onRemove(): void;
}

function BagItem(props: IBagItemProps): JSX.Element {
    const {
        entry,
        entryPath,
        markedAsRemoved,
        contentfulProduct,
        discountBanner,
        tabIndex,
        unavailableModifiers,
        unavailableSubModifiers,
        unavailableChildItems,
        suggestedDeal,
        isUnavailableItem = false,
        isDefaultModifiersUnavailable = false,
        isCondimentsLimitReached,
        condimentsLimit,
        hasSimpleOffer,
    } = props;
    const domainProduct = useDomainProduct(entry.productId);
    const { actions } = useBag();

    if (!domainProduct) {
        return (
            <NonexistentItem
                contentfulProduct={contentfulProduct}
                quantity={entry.quantity}
                onRemoveClick={() => actions.removeFromBag({ lineItemId: entry.lineItemId })}
            />
        );
    }

    return (
        <div
            className={classnames(styles.bagItemContainer, { [styles.bagItemSimpleOffer]: hasSimpleOffer })}
            role="listitem"
            aria-label={domainProduct.name}
        >
            {!markedAsRemoved && (
                <BagItemBlock
                    entry={entry}
                    entryPath={entryPath}
                    contentfulProduct={contentfulProduct}
                    discountBanner={discountBanner}
                    domainProduct={domainProduct}
                    tabIndex={tabIndex}
                    unavailableModifiers={unavailableModifiers}
                    unavailableChildItems={unavailableChildItems}
                    isDefaultModifiersUnavailable={isDefaultModifiersUnavailable}
                    unavailableSubModifiers={unavailableSubModifiers}
                    isUnavailableItem={isUnavailableItem}
                    isCondimentsLimitReached={isCondimentsLimitReached}
                    condimentsLimit={condimentsLimit}
                />
            )}
            {/* can exist only for available item, no extra check needed */}
            {suggestedDeal?.partiallyMatchOffer && <BagItemSuggestedDeal suggestedDeal={suggestedDeal} />}
            {markedAsRemoved && <RemovedItem entry={entry} domainProduct={domainProduct} />}
        </div>
    );
}

function BagItemBlock(props: IBagItemBlockProps): JSX.Element {
    const {
        entry,
        entryPath,
        contentfulProduct,
        discountBanner,
        domainProduct,
        tabIndex,
        unavailableModifiers,
        unavailableChildItems,
        isDefaultModifiersUnavailable,
        unavailableSubModifiers,
        isUnavailableItem,
        isCondimentsLimitReached,
        condimentsLimit,
    } = props;

    const [tooltipIsOpen, setTooltipIsOpen] = useState(false);
    const safeSetTimeout = useSafeSetTimeout();

    const needValidateMaxItemAmount = validateMaxItemAmount();

    const router = useRouter();
    const dispatch = useAppDispatch();

    const bag = useBag();
    const pdp = usePdp();

    const { totalPrice: totallPriceForSingleProduct, calories } = useTallyPriceAndCalories(entry);

    const price = totallPriceForSingleProduct * entry.quantity;

    const isEditable = useIsProductEditable(entry.productId);
    const productHasOtherSizes = useProductHasOtherSizes(entry.productId);

    const shouldShowModifyLink = (isEditable || productHasOtherSizes) && !isUnavailableItem;

    const hasUnsavedModifications = pdp.useHasUnsavedModifications();

    const productQuantity = bag.getProductQuantityById(entry.productId);
    const { currentLocation } = useOrderLocation();
    const maxItemQuantity = currentLocation?.orderLimits?.maxAllowedPerProduct || MAX_PRODUCT_QUANTITY;
    const counts = CHECK_ALLOWED_PER_PRODUCT ? productQuantity : entry.quantity;

    const isCombo = !!entry.childItems?.length;

    const isCondiment = useCheckIsCondiment();
    const isItemCondiment = useMemo(() => {
        return isCondiment(domainProduct.id);
    }, [domainProduct.id, isCondiment]);

    const canIncrement = useMemo(() => {
        if (
            (!isItemCondiment && counts >= maxItemQuantity) ||
            bag.isDiscountLoading ||
            (isCondimentsLimitReached && isItemCondiment)
        ) {
            return false;
        }
        return true;
    }, [counts, bag.isDiscountLoading, isCondimentsLimitReached, maxItemQuantity, isItemCondiment]);

    const canDecrement = useMemo(() => {
        if (entry.quantity <= MIN_PRODUCT_QUANTITY || bag.isDiscountLoading) {
            return false;
        }
        return true;
    }, [entry.quantity, bag.isDiscountLoading]);

    const handleTooltip = (needValidateMaxItemAmount, canIncrement) => {
        if (!needValidateMaxItemAmount) return;

        if (!canIncrement) {
            setTooltipIsOpen(true);
            safeSetTimeout(() => setTooltipIsOpen(false), 2000);
        } else {
            setTooltipIsOpen(false);
        }
    };

    useEffect(() => {
        handleTooltip(needValidateMaxItemAmount, canIncrement);
    }, [needValidateMaxItemAmount, canIncrement]);

    const { id: productGroupId } = useProductGroup(domainProduct?.id) || {};

    const handleItemCount = ({ condition, value, quantity, eventType }) => {
        if (!condition) return;

        const entryIndex = bag.bagEntries.findIndex((e) => e.lineItemId === entry.lineItemId);

        if (entryIndex > -1) {
            bag.actions.updateBagItemCount({ bagEntryIndex: entryIndex, value });
            if (isCondiment(domainProduct?.id)) {
                pdp.actions.onCondimentChange({
                    modifierGroupId: productGroupId,
                    modifierId: domainProduct?.id,
                    quantity,
                });
            } else {
                pdp.actions.editTallyItemCount({
                    pageProductId: domainProduct?.id,
                    lineItemId: entry.lineItemId,
                    value,
                });
            }

            dispatch({
                type: GTM_CHECKOUT_PRODUCT_QUANTITY_CHANGE,
                payload: {
                    eventType,
                    productId: entry.productId,
                    productName: entry.name,
                    quantity,
                },
            });
        }
    };

    const incrementCount = () => {
        handleItemCount({
            condition: canIncrement,
            value: 1,
            quantity: entry.quantity + 1,
            eventType: GtmEventNames.CheckoutProductAddition,
        });
        handleTooltip(needValidateMaxItemAmount, canIncrement);
    };

    const decrementCount = () => {
        handleItemCount({
            condition: canDecrement,
            value: -1,
            quantity: entry.quantity - 1,
            eventType: GtmEventNames.CheckoutProductRemoval,
        });
    };

    const renderQuantity = () => {
        if (SHOULD_SHOW_DISCOUNT_ON_BAG && bag.isDiscountLoading) {
            return <Loader size={20} />;
        }

        if (!needValidateMaxItemAmount) {
            return entry.quantity;
        }

        return (
            <InspireTooltip
                tooltipClassName={styles.tooltipLabel}
                open={tooltipIsOpen}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title={
                    isCondiment(domainProduct.id)
                        ? `You've reached the condiment limit (${condimentsLimit}).`
                        : `You've reached the item limit (${maxItemQuantity}).`
                }
                placement="top"
                theme="dark"
                arrow
                PopperProps={{
                    disablePortal: true,
                    modifiers: {
                        flip: {
                            enabled: false,
                        },
                        preventOverflow: {
                            enabled: false,
                        },
                    },
                }}
            >
                <div>{entry.quantity}</div>
            </InspireTooltip>
        );
    };

    const selectedModifiers = useSelectedModifiers(entry);

    const hasUnavailableModifiersOrSubModifiers = !!(
        unavailableModifiers?.length ||
        unavailableSubModifiers?.length ||
        unavailableChildItems?.length
    );

    const hasUnavailableNotDefaultModifiersOrSubModifiers =
        hasUnavailableModifiersOrSubModifiers && !isDefaultModifiersUnavailable;

    const markAsRemoved = () => {
        if (hasUnsavedModifications) {
            dispatch(requestNavIntercept()).then(({ payload: result }) => {
                if (result) {
                    bag.actions.markAsRemoved({ lineItemId: entry.lineItemId });
                } else {
                    bag.actions.toggleIsOpen({ isOpen: false });
                }
            });
        } else {
            const sauce = selectedModifiers.reduce((acc, modifier) => {
                if (modifier.metadata?.MODIFIER_GROUP_TYPE !== ModifierGroupType.SAUCES) return acc;
                const numberSauces = Object.keys(acc).length;
                return {
                    ...acc,
                    [`sauce${numberSauces + 1}`]: modifier.name || 'none',
                };
            }, {});

            /*
            / Behavior for unavailable items
            / Remove bag item or modifier
            */
            if (isUnavailableItem || hasUnavailableModifiersOrSubModifiers) {
                if (
                    SHOULD_REMOVE_ENTIRE_PRODUCT_IF_MODIFIERS_UNAVAILABLE ||
                    isUnavailableItem ||
                    isCombo ||
                    isDefaultModifiersUnavailable
                ) {
                    bag.actions.removeFromBag({ lineItemId: entry.lineItemId });
                } else {
                    const updatedBagItem = removeUnavailableModifiers(
                        entry,
                        unavailableModifiers,
                        unavailableSubModifiers
                    );
                    bag.actions.editBagLineItem({
                        bagEntry: updatedBagItem,
                    });
                }
            } else {
                /*
                / Behavior by default
                / Mark as removed
                */
                bag.actions.markAsRemoved({ lineItemId: entry.lineItemId });
                // ToDo: needs to be refactored for Multibrand instead of Brand Specific once the other brands are ready in the G Analytics scope
                !USE_GTM_REMOVE_ITEM_FROM_CART
                    ? dispatch({ type: GTM_REMOVE_FROM_CART, payload: { ...entry, sauce } })
                    : dispatch({ type: GTM_REMOVE_FROM_CART_EVENT, payload: { ...entry } });
            }
        }
    };

    const removeTitle =
        hasUnavailableNotDefaultModifiersOrSubModifiers &&
        !isCombo &&
        !SHOULD_REMOVE_ENTIRE_PRODUCT_IF_MODIFIERS_UNAVAILABLE
            ? 'remove unavailable modifier'
            : 'remove product';

    const onModifyClick = (e) => {
        e.preventDefault();

        dispatch(requestNavIntercept()).then(({ payload: result }) => {
            if (result) {
                bag.actions.toggleIsOpen({ isOpen: false });
                bag.actions.customizeBagItem(entry);
                bag.actions.setIsUpdateBagItem(true);
                dispatch({ type: GTM_MODIFY_PRODUCT });
                dispatch({ type: GTM_MODIFY_IN_CART });
                if (entryPath && router) {
                    router.push(entryPath.href, entryPath.as);
                }
            } else {
                bag.actions.toggleIsOpen({ isOpen: false });
            }
        });
    };

    const handleEnterKeyDown = (callback: (e: React.KeyboardEvent<HTMLElement>) => void) => (
        e: React.KeyboardEvent<HTMLElement>
    ) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            callback(e);
        }
    };

    return (
        <div className={styles.bagItemBlockContainer}>
            <div
                className={classnames(styles.bagItem, {
                    [styles.unavailableItem]: hasUnavailableModifiersOrSubModifiers || isUnavailableItem,
                })}
            >
                {discountBanner && SHOW_DISCOUNT_BANNER && (
                    <InspireBadge className="inspireBadge" value={discountBanner} dataTestId="discount-banner" />
                )}

                <ContentfulImage
                    className={styles.bagItemImage}
                    asset={contentfulProduct?.fields?.image}
                    maxWidth={92}
                    inlineStyles={{ aspectRatio: '4 / 3' }}
                />
                <div className={styles.bagItemInfo}>
                    {entry.childItems ? (
                        <ComboBagItemDetail
                            entry={entry}
                            entryPrice={price}
                            domainProduct={domainProduct}
                            unavailableModifiers={unavailableModifiers}
                            unavailableSubModifiers={unavailableSubModifiers}
                            unavailableChildItems={unavailableChildItems}
                            isUnavailableItem={isUnavailableItem}
                            calories={calories}
                            isDiscountLoading={bag.isDiscountLoading}
                        />
                    ) : (
                        <RegularBagItemDetail
                            entry={entry}
                            entryPrice={price}
                            domainProduct={domainProduct}
                            unavailableModifiers={unavailableModifiers}
                            unavailableSubModifiers={unavailableSubModifiers}
                            isUnavailableItem={isUnavailableItem}
                            calories={calories}
                            isDiscountLoading={bag.isDiscountLoading}
                        />
                    )}
                    <div className={classnames(styles.bagItemLinks, { [styles.noBorder]: !shouldShowModifyLink })}>
                        {domainProduct && shouldShowModifyLink && (
                            <InspireLinkButton
                                linkType="secondary"
                                onClick={onModifyClick}
                                onKeyDown={handleEnterKeyDown(onModifyClick)}
                            >
                                {ITEM_MODIFY_TEXT}
                            </InspireLinkButton>
                        )}
                        <InspireLinkButton
                            aria-label="remove bag item"
                            linkType="secondary"
                            onClick={markAsRemoved}
                            onKeyDown={handleEnterKeyDown(markAsRemoved)}
                            title={removeTitle}
                            disabled={bag.isDiscountLoading}
                        >
                            {ITEM_REMOVE_TEXT}
                        </InspireLinkButton>
                    </div>
                </div>
            </div>
            {!(unavailableModifiers || unavailableSubModifiers || unavailableChildItems || isUnavailableItem) ? (
                <div className={styles.quantityControls}>
                    <div
                        tabIndex={tabIndex}
                        aria-label="reduce quantity"
                        className={classnames(styles.quantityControl, styles.bagItemLabel, {
                            [styles.activeControl]: canDecrement,
                        })}
                        onClick={decrementCount}
                        onKeyDown={handleEnterKeyDown(decrementCount)}
                        role="button"
                    >
                        <Icon
                            icon="action-subtract"
                            size="s"
                            variant={canDecrement ? PLUS_MINUS_ICONS_ACTIVE_VARIANT : 'gray4'}
                        />
                    </div>

                    <div
                        aria-label="quantity"
                        className={classnames('t-subheader-small', styles.quantityControl, styles.bagItemLabel)}
                    >
                        {renderQuantity()}
                    </div>
                    <div
                        tabIndex={tabIndex}
                        aria-label="increase quantity"
                        className={classnames(styles.quantityControl, styles.bagItemLabel, {
                            [styles.activeControl]: canIncrement,
                        })}
                        onClick={incrementCount}
                        onKeyDown={handleEnterKeyDown(incrementCount)}
                        role="button"
                    >
                        <Icon
                            icon="action-add"
                            size="s"
                            variant={canIncrement ? PLUS_MINUS_ICONS_ACTIVE_VARIANT : 'gray4'}
                        />
                    </div>
                </div>
            ) : null}
        </div>
    );
}

function RegularBagItemDetail(props: IBagItemDetailsProps): JSX.Element {
    const {
        entry,
        entryPrice,
        domainProduct,
        unavailableModifiers,
        unavailableSubModifiers,
        isUnavailableItem,
        calories,
        isDiscountLoading,
    } = props;

    const { selectProductSize } = useDomainMenuSelectors();
    const isUnavailableInfo = !!unavailableModifiers || !!unavailableSubModifiers || isUnavailableItem;
    const productSize = selectProductSize(domainProduct.id);
    const sizeLabel = getBagItemSizeLabel(productSize);
    const { addedModifiers, removedDefaultModifiers, defaultModifiers, defaultSubModifiersData } = useTallyModifiers(
        entry
    );
    const productItemGroup = useProductItemGroup(domainProduct.id);

    const productName = useMemo(() => {
        if (productItemGroup?.name === ItemGroupEnum.BOGO || productItemGroup?.name === ItemGroupEnum.BOGO_50_OFF) {
            return `${domainProduct.name} (${Number(productSize) * 2} Total)`;
        }
        if (productItemGroup?.name === ItemGroupEnum.BOGO_50_OFF) {
            return `Buy ${productSize} ${domainProduct.name} get ${productSize} 50% off (${
                Number(productSize) * 2
            } Total)`;
        }
        if (sizeLabel) {
            switch (SIZE_PLACEMENT) {
                case 'AFTER_TITLE':
                    return `${domainProduct.name} (${sizeLabel})`;
                case 'IN_BODY':
                    return domainProduct.name;
                case 'BEFORE_TITLE':
                default:
                    return `${sizeLabel} ${domainProduct.name}`;
            }
        }
        return domainProduct.name;
    }, [domainProduct.name, sizeLabel, productItemGroup, productSize]);

    const discountProductAmount = entry?.discounts?.[0]?.amount;
    const discountName = entry?.discounts?.[0]?.name;
    const discountType = entry?.discounts?.[0]?.type;

    return (
        <>
            <div className={styles.bagItemHeader}>
                <TextWithTrademark
                    tag="span"
                    className={classnames(TYPOGRAPHY_CLASS.NAME, styles.bagItemLabel)}
                    text={`${entry.quantity > 1 && isUnavailableInfo ? entry.quantity + 'X ' : ''}${productName}`}
                />
                {!isUnavailableInfo && (
                    <BagItemPrice
                        entryPrice={entryPrice}
                        discountProductAmount={discountProductAmount}
                        isDiscountLoading={isDiscountLoading}
                    />
                )}
            </div>
            {SIZE_PLACEMENT === 'IN_BODY' && sizeLabel && (
                <div className={classnames(styles.productSize, 't-paragraph-hint')}>{sizeLabel}</div>
            )}
            {SHOW_CALORIES_PRODUCT && calories !== undefined && (
                <div className={classnames(styles.productCalories, 't-paragraph-hint')}>{calories} cal</div>
            )}
            {SHOULD_SHOW_DISCOUNT_NAME && discountName && (
                <div className={classnames(styles.discountName, 't-paragraph-hint-strong')}>
                    {discountType === DiscountDetailsTypeModel.PromoCode && 'Promo Code: '}
                    {discountName}
                </div>
            )}
            <ListModifiers
                removedModifiers={removedDefaultModifiers}
                addedModifiers={addedModifiers}
                defaultModifiers={defaultModifiers}
                defaultSubModifiersData={defaultSubModifiersData}
                unavailableModifiers={unavailableModifiers}
                unavailableSubModifiers={unavailableSubModifiers}
            />
        </>
    );
}

function ComboBagItemDetail(props: IBagItemDetailsProps): JSX.Element {
    const {
        entry,
        entryPrice,
        domainProduct,
        unavailableModifiers,
        unavailableSubModifiers,
        unavailableChildItems,
        isUnavailableItem,
        calories,
        isDiscountLoading,
    } = props;
    const isUnavailableInfo = unavailableModifiers?.length || unavailableChildItems?.length || isUnavailableItem;
    const domainProducts = useDomainProducts(entry.childItems?.map((m) => m?.productId));

    const {
        selectProductSize,
        selectDefaultModifiers,
        selectSelectedModifiers,
        selectChildItemsWithDisplayPrices,
    } = useDomainMenuSelectors();

    const discountProductAmount = entry.discounts?.[0]?.amount;

    const childItems: ITallyItemProduct[] = selectChildItemsWithDisplayPrices(entry.childItems, entry.productId)
        ?.map((item) => ({
            item,
            product: domainProducts[item.productId],
        }))
        .filter((item) => !!item.product);

    return (
        <>
            <div className={styles.bagItemHeader}>
                <TextWithTrademark
                    tag="span"
                    text={`${entry.quantity > 1 && isUnavailableInfo ? entry.quantity + 'X ' : ''}${
                        domainProduct.name
                    }`}
                    className={classnames(TYPOGRAPHY_CLASS.NAME, styles.bagItemLabel)}
                />

                {!isUnavailableInfo && (
                    <BagItemPrice
                        entryPrice={entryPrice}
                        discountProductAmount={discountProductAmount}
                        isDiscountLoading={isDiscountLoading}
                    />
                )}
            </div>
            {SHOW_CALORIES_PRODUCT && calories && (
                <div className={classnames(styles.productCalories, 't-paragraph-hint')}>{calories} cal</div>
            )}
            <div className={styles.bagItemModifiers}>
                {childItems.map(({ item, product }) => {
                    const selectedModifiers = selectSelectedModifiers(item);
                    const defaultModifiers = selectDefaultModifiers(product.id);

                    const { addedModifiers, removedDefaultModifiers, modifiersIsChanged } = getChangedModifiers(
                        selectedModifiers,
                        defaultModifiers
                    );

                    const tallyItemPrice = item.price || 0;
                    const sizeLabel = getBagItemSizeLabel(selectProductSize(product.id));
                    const productName =
                        SIZE_PLACEMENT === 'IN_BODY'
                            ? product?.name
                            : `${product?.name} ${sizeLabel ? ` (${sizeLabel})` : ''}`;

                    return (
                        <Fragment key={product.id}>
                            {isUnavailableInfo ? (
                                <>
                                    {!!unavailableChildItems?.includes(product.id) && (
                                        <div className={styles.unavailableChildItem}>
                                            <TextWithTrademark
                                                tag="span"
                                                className={classnames(
                                                    't-paragraph-hint',
                                                    styles.bagUnavailableItemLabel
                                                )}
                                                text={sizeLabel ? `${sizeLabel} ${productName}` : `${productName}`}
                                            />
                                            <span
                                                className={classnames(styles.bagNotAvailableLabel, 't-paragraph-hint')}
                                            >
                                                Not Available
                                            </span>
                                        </div>
                                    )}
                                    {!!unavailableModifiers?.length && (
                                        <ListModifiers
                                            removedModifiers={removedDefaultModifiers}
                                            addedModifiers={addedModifiers}
                                            defaultModifiers={defaultModifiers}
                                            unavailableModifiers={unavailableModifiers}
                                            unavailableSubModifiers={unavailableSubModifiers}
                                        />
                                    )}
                                </>
                            ) : (
                                <>
                                    <div className={styles.bagItemModifier}>
                                        <TextWithTrademark
                                            tag="span"
                                            className={classnames(
                                                't-paragraph-hint',
                                                styles.product,
                                                styles.bagItemLabel
                                            )}
                                            text={`${productName}`}
                                        />
                                        {tallyItemPrice !== 0 && (
                                            <span
                                                className={classnames(
                                                    't-paragraph-hint',
                                                    styles.product,
                                                    styles.bagItemPrice
                                                )}
                                            >
                                                {SHOW_COMBO_PRICE_PLUS && '+'}
                                                {formatPrice(tallyItemPrice)}
                                            </span>
                                        )}
                                    </div>
                                    {SIZE_PLACEMENT === 'IN_BODY' && sizeLabel && (
                                        <div className={classnames(styles.productSize, 't-paragraph-hint')}>
                                            {sizeLabel}
                                        </div>
                                    )}
                                    {modifiersIsChanged && (
                                        <ListModifiers
                                            removedModifiers={removedDefaultModifiers}
                                            addedModifiers={addedModifiers}
                                            defaultModifiers={defaultModifiers}
                                            unavailableModifiers={unavailableModifiers}
                                            unavailableSubModifiers={unavailableSubModifiers}
                                        />
                                    )}
                                </>
                            )}
                        </Fragment>
                    );
                })}
            </div>
        </>
    );
}

/**
 * Transform data about the item removed from bag into an element allowing it to be returned to the bag.
 * @function RemovedItem
 * @param {IRemovedItemProps} props - the item bag entry and domainProduct with supplemental information
 * @property {TallyProductModel} entry - the item's bag entry
 * @property {ItemModel} domainProduct - additional data, including name, about the item
 * @property dispatch
 * @property {useBagHook} bag - hook for bag functionality
 * @property {function} selectProductSize - returns the size of the product
 * @property {function} markAsRemoved - toggles removed state and dispatches GTM events
 * @property {function} handleKeyPress - returns item to cart if enter is pressed
 * @property {string} sizeLabel - the human readable and brand specific size of the product
 * @returns JSX.Element - allows the item to be returned to the bag
 * @added 2023-01-26
 * @version 1.0
 * @author J.R. Misitano <jmisitano@inspirebrands.com>
 * @example <caption> Returns a JSX.Element enabling the item to be restored to the bag </caption>
 * RemovedItem(
    {
        category: 'Burger',
        productId:'idp-sdi-itm-12345-123',
        quantity: 1,
        price: 1.99,
        childItems: []
    },
    {
        id: 'idp-sdi-itm-12345-123',
        name: 'Hamburger',
        sizeGroupId: 'idp-sdi-sig-999-999',
        itemGroupId: 'idp-sdi-itg-999-999',
        productGroupId: 'idp-sdi-prg-000-123'
    }
);
* returns a JSX.Element enabling the item to be restored to the bag
* <div class="item_bagItemContainer__d4lv8" role="listitem" aria-label="Steakhouse Garlic Ribeye Sandwich ">
   <div class="item_removedItemContainer__gIx7L index_vars_removeItemContainer__MIZrG">
      <div class="item_removedItemProduct__QmNUR">
         <div class="item_removedItemProductWrapper__M2iha">
             <span class="textWithTrademark_text__T_Juz t-subheader-small item_removedItemProductName__pit8J">
                 Steakhouse Garlic Ribeye Sandwich
             </span>
             <span class="t-subheader-small item_removedItemProductName__pit8J">
                 &nbsp;was removed!
             </span><
          </div>
      </div>
      <div class="item_removedItemAction__dWnk6">
         <p class="item_removedItemActionLine__aJTTl" tabindex="0">
             <span class="brand-icon action-add BrandIcon_iconContainer__a_MOI BrandIcon_xs__MyHzh"></span>
             <button type="button" class="link_link__DuSU9 linkButton_linkButton__uGGJj link_linkSecondaryActive__1svPt link_vars_link__5TJ4x">
                 ADD THIS ITEM BACK TO YOUR BAG
             </button>
         </p>
      </div>
   </div>
</div>
 */
function RemovedItem(props: IRemovedItemProps): JSX.Element {
    const { entry, domainProduct } = props;
    const dispatch = useAppDispatch();

    const bag = useBag();
    const { selectProductSize } = useDomainMenuSelectors();

    const markAsRemoved = () => {
        bag.actions.markAsRemoved({ lineItemId: entry.lineItemId });

        if (!bag.dealId && SHOULD_SHOW_DISCOUNT_ON_BAG) {
            bag.actions.resetDiscountsOnBag();
        }
        dispatch({
            type: GTM_RESTORE_ITEM_TO_CART,
            payload: {
                ...entry,
                name: entry.name || domainProduct.name,
                reAddCart: true,
            },
        });

        USE_GTM_RE_ADD_ITEM_TO_CART &&
            dispatch({
                type: GTM_RE_ADD_ITEM_TO_CART,
                payload: {
                    ...entry,
                    name: entry.name || domainProduct.name,
                },
            });
    };

    const handleKeyPress = (e: React.KeyboardEvent<HTMLElement>) => {
        if (e.key === 'Enter') {
            markAsRemoved();
        }
    };

    const sizeLabel = getBagItemSizeLabel(selectProductSize(domainProduct.id));

    return (
        <div className={styles.removedItemContainer}>
            <div className={styles.removedItemProduct}>
                <div className={styles.removedItemProductWrapper}>
                    {entry.childItems ? (
                        <TextWithTrademark
                            tag="span"
                            className={classnames('t-subheader-small', styles.removedItemProductName)}
                            text={`${domainProduct.name}`}
                        />
                    ) : (
                        <TextWithTrademark
                            tag="span"
                            className={classnames('t-subheader-small', styles.removedItemProductName)}
                            text={`${domainProduct.name} ${sizeLabel && `(${sizeLabel})`}`}
                        />
                    )}
                    <span className={classnames('t-subheader-small', styles.removedItemProductName)}>
                        &nbsp;{REMOVED_ITEM_TEXT_PART}
                    </span>
                </div>
            </div>
            <div className={styles.removedItemAction} onClick={markAsRemoved}>
                <p className={styles.removedItemActionLine} tabIndex={0} onKeyDown={handleKeyPress}>
                    <Icon icon="action-add" size="xs" />
                    <InspireLinkButton linkType="secondary">{ADD_REMOVED_ITEM_TEXT}</InspireLinkButton>
                </p>
            </div>
        </div>
    );
}

export default BagItem;
