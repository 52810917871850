import React from 'react';
import RichText from '../../atoms/richText';
import styles from './caloriesLegalSection.module.css';
import { BLOCKS, Document } from '@contentful/rich-text-types';
import classnames from 'classnames';

export interface ICaloriesLegalSectionProps {
    text?: Document;
    containerClassName?: string;
}

const CaloriesLegalSection = ({ text, containerClassName }: ICaloriesLegalSectionProps) => {
    const renderNode = {
        [BLOCKS.PARAGRAPH]: (node, next) => `<p class='${styles.caloriesParagraph}'>${next(node.content)}</p>`,
    };
    return (
        <div className={classnames(styles.caloriesContainer, containerClassName)}>
            <RichText text={text} renderNode={renderNode}></RichText>
        </div>
    );
};

export default CaloriesLegalSection;
