import {
    IRewardsDiscountDSResponseModel,
    SellingChannelNamesModel,
    DiscountDetailsTypeModel,
    TallyDiscountTypeModel,
    TallyFulfillmentTypeModel,
    TallyProductModel,
} from '../../@generated/webExpApi';
import { useOrderLocation, useSelectedSell } from '../../redux/hooks';
import { useProducts } from '../../redux/hooks/domainMenu';
import { setTallyProductWithModifiers, createTallyRequest } from '../helpers/tallyHelper';
import { useDealsService } from '../../common/hooks/useDealsService';

interface UseBagDiscountHook {
    getOffersDiscount: (
        bagEntries: TallyProductModel[],
        subTotal: string,
        orderTime?: Date,
        dealId?: string,
        customerId?: string
    ) => Promise<IRewardsDiscountDSResponseModel>;
}

export const useBagDiscount = (): UseBagDiscountHook => {
    const { deliveryAddress, isPickUp, pickupAddress } = useOrderLocation();
    const domainProducts = useProducts();
    const dealsService = useDealsService();

    const fulfillmentType = isPickUp ? TallyFulfillmentTypeModel.PickUp : TallyFulfillmentTypeModel.Delivery;
    const locationId = isPickUp ? pickupAddress.id : deliveryAddress?.pickUpLocation?.id;
    const { correlationId: ssCorrelationId } = useSelectedSell();
    const deliveryLocation = !isPickUp && deliveryAddress?.deliveryLocation;

    const getOffersDiscount = (
        products: TallyProductModel[],
        subTotal: string,
        orderTime?: Date,
        dealId?: string,
        customerId?: string
    ): Promise<IRewardsDiscountDSResponseModel> => {
        return dealsService.getAccountOffersDiscount({
            iRewardsDiscountRequestModel: {
                tallyRequest: createTallyRequest({
                    products: setTallyProductWithModifiers(products, domainProducts),
                    locationId: String(locationId),
                    fulfillmentType,
                    orderTime,
                    discounts: {
                        discountType: TallyDiscountTypeModel.Provided,
                        discountDetails: [
                            {
                                code: dealId,
                                type: DiscountDetailsTypeModel.OfferCode,
                            },
                        ],
                    },
                    customerId,
                    ssCorrelationId,
                    deliveryLocation,
                }),
                subTotal,
            },
            sellingChannel: SellingChannelNamesModel.Weboa,
        });
    };

    return {
        getOffersDiscount,
    };
};
