import React, { FC, useState, useEffect } from 'react';
import classnames from 'classnames';
import { usePromocode } from '../../../../../common/hooks/usePromocode';
import { PromoCode } from '../promoCode';
import BrandLoader from '../../../../atoms/BrandLoader';
import styles from './bagPromocodeContainer.module.css';

interface IBagPromocodeContainer {
    disabled?: boolean;
}

export const LOADER_SUBHEADING_TEXT = 'Please Wait';
export const LOADER_HEADING_TEXT = 'Your Promo Code is Being Applied.';
export const DEFAULT_HELPER_TEXT = 'Limited to 1 Promo Code Per Order';

export const BagPromocodeContainer: FC<IBagPromocodeContainer> = ({ disabled = false }) => {
    const { applyPromocode, resetPromocode, isLoading, isApplied, error } = usePromocode();
    const [inputValue, setInputValue] = useState<string>('');

    const { errorMessage, isValidationError } = error || {};
    const helperText = errorMessage || DEFAULT_HELPER_TEXT;

    const onApplyClick = (): void => {
        if (inputValue) {
            applyPromocode(encodeURI(inputValue));
        }
    };

    const onRemoveClick = (): void => {
        resetPromocode();
        setInputValue('');
    };

    const onValueChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setInputValue(event.currentTarget.value.toUpperCase());
        if (errorMessage) {
            resetPromocode();
        }
    };

    const onKeyPress = (event: React.KeyboardEvent<HTMLInputElement>): void => {
        if (event.key === 'Enter' && !errorMessage) {
            onApplyClick();
        }
    };

    useEffect(() => {
        isApplied && setInputValue('');
    }, [isApplied]);

    if (isApplied) {
        return null;
    }

    return (
        <>
            <PromoCode
                inputValue={inputValue}
                helperText={helperText}
                isError={!!errorMessage}
                isValidationError={isValidationError}
                onChange={onValueChange}
                onApplyClick={onApplyClick}
                onRemoveClick={onRemoveClick}
                onKeyPress={onKeyPress}
                disabled={disabled || isLoading}
            />
            {isLoading && (
                <div className={styles.loaderContainer}>
                    <BrandLoader className={styles.loader} />
                    <div className={styles.content}>
                        <p className={classnames('t-subheader-small', 't-stylized', styles.subheading)}>
                            {LOADER_SUBHEADING_TEXT}
                        </p>
                        <h1 className={classnames('t-header-h1', styles.heading)}>{LOADER_HEADING_TEXT}</h1>
                    </div>
                </div>
            )}
        </>
    );
};
