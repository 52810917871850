import React, { FC } from 'react';
import ContentfulImage from '../ContentfulImage';
import InspireTooltip from '../Tooltip';
import Icon from '../BrandIcon';
import classnames from 'classnames';
import { Asset } from 'contentful';

import styles from './condimentItem.module.css';
interface ICondimentItem {
    image: Asset;
    displayName: string;
    calories: number;
    limitReachedItemId?: string;
    productId: string;
    tooltipMessage: string;
    quantity: number;
    isCondimentsLimitReached: boolean;
    caloriesClassName?: string;
    isDrinkCarrier?: boolean;
    handleCondimentMinus: (productId: string, quantity: number, displayName: string) => void;
    handleCondimentPlus: (productId: string, quantity: number, displayName: string) => void;
}

export const CondimentItem: FC<ICondimentItem> = ({
    image,
    displayName,
    calories,
    limitReachedItemId,
    productId,
    tooltipMessage,
    quantity,
    isCondimentsLimitReached,
    handleCondimentMinus,
    handleCondimentPlus,
    caloriesClassName,
    isDrinkCarrier,
}) => {
    return (
        <div className={styles.condimentWrapper} data-testid={'condiment-item'}>
            <ContentfulImage asset={image} maxWidth={59} className={styles.image} />
            <div className={styles.textBlock}>
                <span
                    className={classnames('t-product-title-small truncate-at-2', {
                        [styles.drinkCarrier]: isDrinkCarrier,
                    })}
                >
                    {displayName}
                </span>
                {!isDrinkCarrier && (
                    <span className={classnames('t-paragraph-hint', caloriesClassName)}>{calories || 0} cal</span>
                )}
            </div>
            <InspireTooltip
                open={limitReachedItemId === productId}
                tooltipClassName={styles.tooltip}
                placement="top"
                disableFocusListener
                disableHoverListener
                disableTouchListener
                theme="dark"
                title={tooltipMessage}
                arrow
            >
                <div className={styles.controller}>
                    <div
                        className={classnames(styles.iconMinus, styles.icons, {
                            [styles.disabled]: quantity === 0,
                        })}
                        onClick={() => handleCondimentMinus(productId, quantity - 1, displayName)}
                        role="button"
                        aria-label={`remove one ${displayName}`}
                    >
                        <Icon size="xs" icon="action-subtract" />
                    </div>

                    <div className={classnames('t-subheader-universal-smaller', styles.quantity)}>{quantity}</div>
                    <div
                        className={classnames(styles.icons, styles.iconPlus, {
                            [styles.disabled]: isCondimentsLimitReached,
                        })}
                        onClick={() => handleCondimentPlus(productId, quantity + 1, displayName)}
                        role="button"
                        aria-label={`add one ${displayName}`}
                    >
                        <Icon size="xs" icon="action-add" />
                    </div>
                </div>
            </InspireTooltip>
        </div>
    );
};
