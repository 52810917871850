import React, { useCallback, useEffect, useRef, useState } from 'react';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import Modal from '@material-ui/core/Modal';
import classnames from 'classnames';
import { isAfter, parseISO, utcToZonedTime } from '../../../common/helpers/dateTime';

import { IAvailableTimesModel, TallyProductModel, TServiceTypeModel } from '../../../@generated/webExpApi';

import {
    useAccount,
    useBag,
    useConfiguration,
    useDealWarning,
    useDomainMenu,
    useGlobalProps,
    useOrderLocation,
    usePageLoader,
    usePdp,
    useRewards,
    useSelectedSell,
} from '../../../redux/hooks';
import { formatPrice } from '../../../lib/domainProduct';
import Icon from '../../atoms/BrandIcon';
import { useDomainProducts, useTallyItemsWithPricesAndCalories } from '../../../redux/hooks/domainMenu';
import useTallyOrder from '../../../redux/hooks/useTallyOrder';
import { actions } from '../../../redux/tallyOrder';
import { GtmErrorCategory, GtmErrorEvent } from '../../../common/services/gtmService/types';
import {
    GTM_BEGIN_CHECKOUT_VIEW,
    GTM_CHECKOUT_VIEW,
    GTM_ERROR_EVENT,
    GTM_USER_ID,
} from '../../../common/services/gtmService/constants';
import {
    getBagEntriesWithoutCondiments,
    getTallyBagEntries,
    getTallyBagProductIds,
} from '../../../common/helpers/bagHelper';
import { useAppDispatch } from '../../../redux/store';
import { requestNavIntercept } from '../../../redux/navIntercept';
import BagContentContainer from './bagContentContainer';
import { getCheckoutEntries, hasCheckoutEntries } from './bagModel';
import { NetworkErrorMessage } from '../../../common/services/createErrorWrapper';
import { resolveOpeningHours } from '../../../lib/locations';
import { usePrevious } from '../../../common/hooks/usePreviousState';
import isMobileScreen from '../../../lib/isMobileScreen';
import isSmallScreen from '../../../lib/isSmallScreen';
import { isEpsilonRewardsOn, isShoppingBagLocationSelectComponentEnabled } from '../../../lib/getFeatureFlags';
import LocationSelect from '../navigation/locationSelect';

import styles from './index.module.css';
import { ITEMS_NOT_AVAILABLE_FOR_LOCATION, ORDER_NOT_AVAILABLE_FOR_LOCATION } from '../../../common/constants/bag';
import BagLoyaltyBanner from './bagLoyaltyBanner';
import useBagAvailableItemsCounter from '../../../common/hooks/useBagAvailableItemsCounter';
import {
    ARE_CONDIMENTS_ENABLED,
    CLOSE_BAG_TITLE,
    getTimeValues,
    IS_INCLUDE_SOURCE_PRODUCT_ID,
    SERVICE_TYPE,
    SHOULD_NOT_REMOVE_DEAL_AFTER_REMOVE_UNAVAILABLE_ITEMS,
    SHOULD_SHOW_DISCOUNT_ON_BAG,
    SHOW_DIVIDER,
    SHOW_EXPIRED_REWARD_WARNING,
    SHOW_NUMBER_ITEMS,
    SHOW_OVER_LIMIT_SIMPLE_TEXT,
    TYPOGRAPHY_CLASS,
} from './constants';
import { useBagUnavailableItems } from '../../../common/hooks/useBagUnavailableItems';
import { useBagRenderItems } from '../../../common/hooks/useBagRenderItems';
import { WorkingHours } from '../../../redux/bag';
import { BagCondiments } from './bagCondiments/bagCondiments';
import { useCheckIsCondiment } from '../../../common/hooks/useCheckIsCondiment';
import Loader from '../../atoms/Loader';
import { useBagDiscount } from '../../../common/hooks/useBagDiscount';
import DealWarningModal from '../../molecules/dealWarningModal';
import useDeal from '../../../common/hooks/useDeal';
import { SHOULD_FILTER_TIME_SLOTS } from '../checkout/constants';
import { addMinutes } from 'date-fns';
import { TIME_NEEDED_FOR_KITCHEN_TO_PREPARE } from '../../../common/constants/timeslots';

const BagButtons = dynamic(import('./bagButtons'), {
    ssr: false,
    // eslint-disable-next-line react/display-name
    loading: () => null,
});

enum TooltipTypes {
    ORDER_NOT_AVAILABLE_FOR_LOCATION,
    ITEMS_NOT_AVAILABLE_FOR_LOCATION,
}

export interface TimeSlotValues {
    arbysTimeslotValues: WorkingHours;
    bwwTimeslotValues: IAvailableTimesModel;
}

interface IBag {
    isBagStaysOpen: boolean;
    setIsBagStaysOpen: (payload: boolean) => void;
}

function Bag({ isBagStaysOpen, setIsBagStaysOpen }: IBag): JSX.Element {
    const bag = useBag();
    const router = useRouter();
    const dispatch = useAppDispatch();
    const globalProps = useGlobalProps();
    const {
        isLoading,
        submitTallyOrder,
        error,
        unavailableItems: unavailableItemsIds,
        setUnavailableTallyItems,
    } = useTallyOrder();

    const allItemsIds = getTallyBagProductIds(bag.bagEntries);
    const domainProducts = useDomainProducts(allItemsIds);
    const { account } = useAccount();
    const smallScreen = isSmallScreen();
    const mobileScreen = isMobileScreen();
    const suggestedSell = useSelectedSell();
    const {
        actions: { resetPdpCondimentsState },
    } = usePdp();
    const ref = useRef<HTMLDivElement>(null);
    const [isDropdownOpen, setDropdownToggle] = useState<boolean>(false);
    const [condimentsWindowOpen, setCondimentsWindowOpen] = useState<boolean>(false);
    const [onCheckoutInvalidTimeError, setOnCheckoutInvalidTimeError] = useState(null);

    const isLocationSelectComponentEnabled = isShoppingBagLocationSelectComponentEnabled();
    const bagAvailableCount = useBagAvailableItemsCounter();
    const {
        currentLocation: location,
        isCurrentLocationOAAvailable,
        isPickUp,
        method,
        pickupAddress,
        deliveryAddress,
        pickupLocationTimeSlots,
        deliveryLocationTimeSlots,
    } = useOrderLocation();

    const { unavailableItems } = useBagUnavailableItems(unavailableItemsIds);

    const {
        configuration: { isOAEnabled },
    } = useConfiguration();

    const { getOffersDiscount } = useBagDiscount();

    useEffect(() => {
        if (bag.isOpen) {
            bag.actions.toggleIsOpen({ isOpen: false });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (account && isBagStaysOpen) {
            bag.actions.toggleIsOpen({ isOpen: true });
            setIsBagStaysOpen(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isBagStaysOpen, account]);

    const checkIsCondiment = useCheckIsCondiment();

    useEffect(() => {
        bag.bagEntries.forEach((item) => {
            const isCondiment = checkIsCondiment(item.productId);
            if (isCondiment) {
                if (
                    !(globalProps?.modifierItemsById?.[item.productId] || globalProps?.modifierItemsById?.['default'])
                ) {
                    bag.actions.removeFromBag({ lineItemId: item.lineItemId });
                }
            } else {
                if (!globalProps?.productsById?.[item.productId]) {
                    bag.actions.removeFromBag({ lineItemId: item.lineItemId });
                }
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // eslint-disable-next-line testing-library/render-result-naming-convention
    const renderItems = useBagRenderItems();

    const isCheckoutDisabledByTally = renderItems.some((item) =>
        unavailableItems.find((el) => el.lineItemId === item.entry.lineItemId && el.isUnavailableByTally)
    );

    const unavailableRenderItems = renderItems.filter((item) => !item.isAvailable);
    const numberOfUnavailableItems = unavailableRenderItems.reduce((acc, curr) => acc + curr.entry.quantity, 0);

    const checkoutEntries = getCheckoutEntries(renderItems);

    const {
        error: menuError,
        loading: isMenuLoading,
        actions: { getDomainMenu },
    } = useDomainMenu();

    const bagItemPrices = useTallyItemsWithPricesAndCalories(checkoutEntries);

    const bagEmpty = bag.bagEntries.length === 0;
    const { image, description, header } = globalProps.emptyShoppingBag.fields;

    const totalPrice = bagItemPrices.reduce((acc, curr) => acc + curr.productData.price * curr.quantity, 0);
    const subTotal = bagItemPrices.reduce((acc, curr) => acc + curr.productData.totalPrice * curr.quantity, 0);

    const maxOrderAmount = location?.additionalFeatures?.maxOrderAmount;
    const isOverLimitForOrder = subTotal >= maxOrderAmount;
    const totalDiscount = formatPrice(subTotal) !== formatPrice(totalPrice) && formatPrice(subTotal - totalPrice);

    const {
        getOfferById,
        isApplyPromocodeLoading,
        appliedPromocode,
        actions: { removePromocode },
    } = useRewards();
    const offer = getOfferById(bag.dealId);
    const isPromocodeApplied = !!appliedPromocode;

    const dealWarning = useDealWarning(bag.dealId, SHOW_EXPIRED_REWARD_WARNING);

    const [dealWarningModalOpen, setDealWarningModalOpen] = useState(false);

    const toggleDealWarningModal = useCallback(() => {
        setDealWarningModalOpen(!dealWarningModalOpen);
    }, [dealWarningModalOpen]);

    const toggleBag = useCallback(() => {
        if (isApplyPromocodeLoading) {
            return;
        }
        bag.actions.toggleIsOpen({ isOpen: !bag.isOpen });
    }, [bag.actions, bag.isOpen, isApplyPromocodeLoading]);

    const {
        actions: { showLoader, hideLoader },
    } = usePageLoader();

    useEffect(() => {
        if (checkoutEntries.length > 0 && bag.isOpen && suggestedSell.correlationId) {
            suggestedSell.actions.fetchRecommendedProducts({
                locationId: location.id,
                correlationId: suggestedSell.correlationId,
                productIds: checkoutEntries.reduce(
                    (productIdList, current) => [...productIdList, ...Array(current.quantity).fill(current.productId)],
                    []
                ),
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bag.isOpen, suggestedSell.correlationId, checkoutEntries.length, bag.bagEntriesCount]);

    useEffect(() => {
        if (isMenuLoading === false && !menuError) {
            dispatch(bag.actions.updateBagItems());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMenuLoading]);

    const resolvedOpeningHours = resolveOpeningHours(location, TServiceTypeModel.OrderAhead);
    const storeIsOpenNow = !!resolvedOpeningHours && resolvedOpeningHours.isOpen;

    const {
        actions: { setPickupTime, initPickupTimeValues, resetPickupTime, resetDeliveryTime },
        pickupTimeValues,
        orderTime,
        orderTimeType,
    } = bag;

    const { id: pickupAddressId } = pickupAddress || {};
    const { id: deliveryAddressId } = deliveryAddress?.locationDetails || {};

    const prevPickupAddressId = usePrevious(pickupAddressId);
    const prevDeliveryAddressId = usePrevious(deliveryAddressId);
    const userAgent = typeof window !== 'undefined' && navigator?.userAgent;

    useEffect(() => {
        const openingHours = pickupAddress && resolveOpeningHours(pickupAddress, SERVICE_TYPE);

        if (prevPickupAddressId && prevPickupAddressId !== pickupAddressId) {
            resetPickupTime();
        }

        if (openingHours) {
            initPickupTimeValues({
                openedTimeRanges: openingHours.openedTimeRanges,
                timezone: openingHours.storeTimezone,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pickupAddressId]);

    useEffect(() => {
        if (prevDeliveryAddressId && prevDeliveryAddressId !== deliveryAddressId) {
            resetDeliveryTime();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deliveryAddressId]);

    const isInitialRender = useRef(true);

    useEffect(() => {
        if (!isInitialRender.current) {
            bag.actions.toggleIsOpen({ isOpen: false });
        } else if (router.pathname !== '/callback') {
            isInitialRender.current = false;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [router.pathname]);

    useEffect(() => {
        const availableBagEntriesWithoutCondiments = getBagEntriesWithoutCondiments(
            renderItems,
            checkIsCondiment,
            bag.entriesMarkedAsRemoved
        );
        if (!isMenuLoading && !availableBagEntriesWithoutCondiments.length) {
            const condimentsLineItemIdList = renderItems
                .filter(({ entry }) => checkIsCondiment(entry?.productId))
                .map(({ entry }) => entry?.lineItemId);

            if (condimentsLineItemIdList.length) {
                bag.actions.removeAllFromBag({
                    lineItemIdList: condimentsLineItemIdList,
                });
                resetPdpCondimentsState();
            }
        }
    }, [renderItems, bag.actions, bag.entriesMarkedAsRemoved, checkIsCondiment, isMenuLoading]);

    const onCheckoutClick = useCallback(() => {
        if (dealWarningModalOpen) {
            if (dealWarning.isOfferExpired) {
                if (isPromocodeApplied) {
                    removePromocode(offer.userOfferId);
                }

                bag.actions.removeDealFromBag();
                bag.actions.resetDiscountsOnBag();
            }

            toggleDealWarningModal();
        } else if (dealWarning.checkIfOfferExpired?.()) {
            toggleDealWarningModal();
            return;
        }

        let time = orderTime && new Date(orderTime);

        // if store close and pickup time not choose before
        if (!storeIsOpenNow && !orderTime) {
            const timeslots = {
                arbysTimeslotValues: pickupTimeValues,
                bwwTimeslotValues: isPickUp ? pickupLocationTimeSlots?.pickup : deliveryLocationTimeSlots?.delivery,
            } as TimeSlotValues;

            const firstAvailableDate = getTimeValues(timeslots);

            if (firstAvailableDate) {
                time = new Date(firstAvailableDate);
                setPickupTime({ time: firstAvailableDate, method });
            }
        }

        if (isPickUp && SHOULD_FILTER_TIME_SLOTS) {
            const nineTeenMinutesFromNow = addMinutes(
                utcToZonedTime(new Date(Date.now()), location?.timezone),
                TIME_NEEDED_FOR_KITCHEN_TO_PREPARE
            );
            if (!time) {
                const firstAvailableDate = getTimeValues({
                    bwwTimeslotValues: pickupLocationTimeSlots?.pickup,
                    arbysTimeslotValues: null,
                });

                if (firstAvailableDate) {
                    time = new Date(firstAvailableDate);
                }
            }

            const timeSlot = utcToZonedTime(time, location?.timezone);
            if (time && !(timeSlot.getTime() > nineTeenMinutesFromNow.getTime())) {
                setDropdownToggle(true);
                setOnCheckoutInvalidTimeError(
                    'Previously selected Pickup time is no longer available. The next available time has been selected.'
                );
                return;
            }
        }

        const currentDate = new Date(Date.now());
        //if previously selected future time passed for Delivery
        if (!isPickUp && orderTime && orderTimeType === 'future' && isAfter(currentDate, parseISO(orderTime))) {
            time = null;
        }

        dispatch(requestNavIntercept()).then(({ payload: result }) => {
            if (result) {
                bag.actions.clearLastRemovedLineItemId();
                showLoader();
                submitTallyOrder(
                    getTallyBagEntries(checkoutEntries, domainProducts, location, time, IS_INCLUDE_SOURCE_PRODUCT_ID),
                    time,
                    dealWarning.isOfferExpired ? null : bag?.dealId,
                    account?.idpCustomerId
                )
                    .then((action) => {
                        switch (action.type) {
                            case actions.fulfilled.type: {
                                toggleBag();
                                router.push('/checkout');
                                dispatch({ type: GTM_CHECKOUT_VIEW, payload: bag.bagEntries });
                                dispatch({
                                    type: GTM_BEGIN_CHECKOUT_VIEW,
                                    payload: { checkoutEntries, domainProducts },
                                });
                                dispatch({
                                    type: GTM_USER_ID,
                                    payload: {
                                        userID: account ? account?.idpCustomerId : suggestedSell.correlationId,
                                        isLoggedIn: !!account,
                                        userAgent,
                                    },
                                });
                                const onRouterComplete = () => {
                                    hideLoader();
                                    router.events.off('routeChangeComplete', onRouterComplete);
                                };

                                const onRouterError = () => {
                                    hideLoader();
                                    router.events.off('routeChangeError', onRouterError);
                                };

                                router.events.on('routeChangeComplete', onRouterComplete);
                                router.events.on('routeChangeError', onRouterError);
                                setUnavailableTallyItems([]);
                                break;
                            }
                            case actions.invalid.type: {
                                if (action.payload.message !== NetworkErrorMessage) {
                                    getDomainMenu(location);
                                }
                                hideLoader();
                                break;
                            }
                            case actions.setUnavailableItems.type: {
                                hideLoader();
                                break;
                            }
                            default:
                                break;
                        }
                    })
                    .catch(() => {
                        hideLoader();
                    });
            } else {
                bag.actions.toggleIsOpen({ isOpen: false });
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bag, orderTime, isPromocodeApplied, offer?.userOfferId, dealWarningModalOpen]);

    const { getDealRedirectUrlByRedeem } = useDeal();

    const handleAddMoreItemsClick = useCallback(() => {
        toggleBag();
        toggleDealWarningModal();

        if (dealWarning.isOfferExpired) {
            return router.push('/menu');
        }

        router.push(
            getDealRedirectUrlByRedeem(
                offer.type,
                offer.applicability?.eligibleIds || [],
                offer.applicability?.buyCount,
                offer.applicability?.isIncludesAll
            )
        );
    }, [getDealRedirectUrlByRedeem, toggleDealWarningModal, offer, router, toggleBag, dealWarning.isOfferExpired]);

    const onRemoveUnavailable = (unavailableEntries: TallyProductModel[]) => {
        bag.actions.removeAllFromBag({
            lineItemIdList: unavailableEntries.map((entry) => entry.lineItemId),
            shouldNotRemoveDeal: SHOULD_NOT_REMOVE_DEAL_AFTER_REMOVE_UNAVAILABLE_ITEMS,
        });
    };

    const noCheckoutEntries = !isMenuLoading && hasCheckoutEntries(renderItems) === false;
    const isOrderAheadAvailable = isCurrentLocationOAAvailable;

    const getTooltipType = (): TooltipTypes | null => {
        if (error) {
            if (!isOrderAheadAvailable) {
                return TooltipTypes.ORDER_NOT_AVAILABLE_FOR_LOCATION;
            }
            if (noCheckoutEntries) {
                return TooltipTypes.ITEMS_NOT_AVAILABLE_FOR_LOCATION;
            }
            return null;
        }

        return null;
    };

    const tooltipMap = {
        [TooltipTypes.ORDER_NOT_AVAILABLE_FOR_LOCATION]: {
            message: ORDER_NOT_AVAILABLE_FOR_LOCATION,
            onDismiss: null,
            dismiss: false,
        },
        [TooltipTypes.ITEMS_NOT_AVAILABLE_FOR_LOCATION]: {
            message: ITEMS_NOT_AVAILABLE_FOR_LOCATION,
            onDismiss: null,
            dismiss: false,
        },
    };

    const tooltip = tooltipMap[getTooltipType()];

    useEffect(() => {
        if (isOverLimitForOrder) {
            const payload = {
                ErrorCategory: GtmErrorCategory.BAG_ERROR_MAX_TOTAL,
                ErrorDescription: `Online orders over ${maxOrderAmount} cannot be placed. Please call the restaurant to help fulfill your order: ${location.contactDetails.phone}`,
            } as GtmErrorEvent;

            dispatch({ type: GTM_ERROR_EVENT, payload });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOverLimitForOrder, maxOrderAmount]);

    useEffect(() => {
        if (!bag.isOpen) {
            setCondimentsWindowOpen(false);
        }
    }, [bag.isOpen]);

    const renderTooltipText = () => {
        if (SHOW_OVER_LIMIT_SIMPLE_TEXT) {
            return `Online order can’t exceed $${maxOrderAmount?.toFixed(2) || ''}`;
        }

        return (
            <span>
                Online orders over ${maxOrderAmount} cannot be placed. Please call the restaurant to help fulfill your
                order: &nbsp;
                <a className={styles.phoneLink} href={`tel:${location?.contactDetails.phone}`}>
                    {location?.contactDetails.phone}
                </a>
            </span>
        );
    };

    return (
        <div className={styles.container}>
            <Modal className={styles.modal} open={bag.isOpen} onClose={toggleBag} aria-label="Bag">
                <div className={styles.content}>
                    {condimentsWindowOpen && ARE_CONDIMENTS_ENABLED ? (
                        <BagCondiments handleCondimentsWindow={setCondimentsWindowOpen} />
                    ) : (
                        <>
                            <button className={classnames('t-subheader-small', styles.closeButton)} onClick={toggleBag}>
                                <Icon
                                    icon="action-close"
                                    size={smallScreen || mobileScreen ? 's' : 'm'}
                                    className={styles.closeButtonIcon}
                                />
                                {CLOSE_BAG_TITLE}
                            </button>
                            {isLocationSelectComponentEnabled && (
                                <div className={styles.locationSelectWrapper} ref={ref}>
                                    {SHOW_DIVIDER && <div className={styles.divider} />}
                                    <LocationSelect
                                        className={styles.locationSelect}
                                        infoClassName={styles.locationSelectInfo}
                                        locationBlockClassName={styles.locationSelectLocationBlock}
                                        iconClassName={styles.locationSelectIcon}
                                        chevronIconClassName={styles.locationSelectChevronIcon}
                                        containerClassName={styles.locationSelectContainer}
                                        locationDropdownClassName={styles.bagNavigationDropdownContent}
                                        locationDropdownModalStyle={{}}
                                        locationDropdownModalClassName={styles.bagNavigationDropdownModal}
                                        disablePortal={false}
                                        navRef={ref}
                                        setDropdownToggle={setDropdownToggle}
                                        isDropdownOpen={isDropdownOpen}
                                        onCheckoutInvalidTimeError={onCheckoutInvalidTimeError}
                                        clearInvalidTimeError={() => setOnCheckoutInvalidTimeError(null)}
                                    />
                                </div>
                            )}
                            {!!numberOfUnavailableItems && !isMenuLoading && (
                                <div className={classnames('t-paragraph-small-strong', styles.unavailableNotification)}>
                                    {`You have ${numberOfUnavailableItems} unavailable item${
                                        numberOfUnavailableItems > 1 ? 's' : ''
                                    } in your bag.`}
                                </div>
                            )}
                            <div
                                className={classnames(styles.bag, {
                                    [styles.bagEmpty]: bagEmpty,
                                })}
                            >
                                {!isMenuLoading && (
                                    <div className={styles.headerContainer}>
                                        <div className={styles.yourBagHeaderContainer}>
                                            <h2 className={classnames('t-header-h1', styles.header)}>My Bag</h2>
                                            {SHOW_NUMBER_ITEMS && bagAvailableCount > 0 && (
                                                <span
                                                    aria-label="bag item count"
                                                    className={styles.itemCount}
                                                >{`(${bagAvailableCount} ITEM${
                                                    bagAvailableCount === 1 ? '' : 'S'
                                                })`}</span>
                                            )}
                                        </div>
                                        {!bagEmpty && (
                                            <div className={styles.total}>
                                                <span className={classnames('t-paragraph-hint', styles.totalHint)}>
                                                    TOTAL
                                                </span>
                                                {SHOULD_SHOW_DISCOUNT_ON_BAG && bag.isDiscountLoading ? (
                                                    <Loader size={34} />
                                                ) : (
                                                    <span
                                                        aria-label="bag total price"
                                                        className={classnames(
                                                            TYPOGRAPHY_CLASS.PRICE_TEXT,
                                                            styles.priceHint
                                                        )}
                                                    >
                                                        {formatPrice(
                                                            SHOULD_SHOW_DISCOUNT_ON_BAG && bag.bagDiscount > 0
                                                                ? Number(subTotal - bag.bagDiscount)
                                                                : totalPrice
                                                        )}
                                                    </span>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                )}
                                {isEpsilonRewardsOn() && <BagLoyaltyBanner />}

                                <BagContentContainer
                                    isMenuLoading={isMenuLoading}
                                    totalPrice={formatPrice(totalPrice)}
                                    renderItems={renderItems}
                                    onRemoveUnavailable={onRemoveUnavailable}
                                    subTotal={formatPrice(subTotal)}
                                    subTotalBeforeDiscounts={subTotal}
                                    warningMessage={dealWarning.message}
                                    isOfferApplicable={dealWarning.isOfferApplicable}
                                    totalDiscount={totalDiscount}
                                    tooltipText={renderTooltipText()}
                                    isOverLimitForOrder={isOverLimitForOrder}
                                    unavailableItems={unavailableItems}
                                    caloriesLegal={globalProps.caloriesLegal}
                                    isBagEmpty={bagEmpty}
                                    emptyBagFields={{ image, description, header }}
                                    handleCondimentsWindow={setCondimentsWindowOpen}
                                    bagTallyEntries={getTallyBagEntries(
                                        checkoutEntries,
                                        domainProducts,
                                        location,
                                        null,
                                        IS_INCLUDE_SOURCE_PRODUCT_ID
                                    )}
                                    getOffersDiscount={getOffersDiscount}
                                />
                            </div>
                            <BagButtons
                                location={location}
                                isLoading={isLoading || isMenuLoading}
                                renderItems={renderItems}
                                toggleBag={toggleBag}
                                onCheckoutClick={
                                    SHOULD_SHOW_DISCOUNT_ON_BAG && dealWarning.message
                                        ? toggleDealWarningModal
                                        : onCheckoutClick
                                }
                                noCheckoutEntries={noCheckoutEntries}
                                tooltipText={tooltip?.message}
                                onTooltipDismiss={tooltip?.onDismiss}
                                tooltipDismiss={tooltip?.dismiss}
                                isOverLimitForOrder={isOverLimitForOrder}
                                isCheckoutDisabledByTally={isCheckoutDisabledByTally}
                                disabled={!isOAEnabled}
                            />
                        </>
                    )}
                </div>
            </Modal>
            {SHOULD_SHOW_DISCOUNT_ON_BAG && offer && (
                <DealWarningModal
                    open={dealWarningModalOpen}
                    dealName={offer.name}
                    isPromoCode={isPromocodeApplied}
                    isOfferApplicable={dealWarning.isOfferApplicable}
                    isOfferExpired={dealWarning.isOfferExpired}
                    onClose={toggleDealWarningModal}
                    onCheckoutClick={onCheckoutClick}
                    onAddMoreItemsClick={handleAddMoreItemsClick}
                />
            )}
        </div>
    );
}

export default Bag;
