import { useMemo } from 'react';
import UAParser from 'ua-parser-js';

export const useDevice = () => {
    const parser = useMemo(() => new UAParser(), []);
    const { type } = parser.getDevice();

    const isMobile = ['mobile', 'tablet'].includes(type);
    const deviceType = isMobile ? 'mobile' : 'desktop';

    return { type, deviceType };
};
