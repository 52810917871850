export const TEXT_DISCOUNT = 'Discounts Applied';
export const TEXT_PROMO_CODE = 'Promo Code Applied';

export const CLASS_EXT = '';

export const TYPOGRAPHY_CLASS = {
    TOTAL_ITEM: 't-paragraph-small',
    TOTAL_ITEM_MOBILE: 't-paragraph-small',
    TOTAL_LINE: 't-paragraph-strong',
};

export const SHOW_MOBILE_LABEL = false;
