import { Asset } from 'contentful';
import { IRewardMenuIdModel, TOfferTypeModel } from '../../@generated/webExpApi';
import { getProductPagePathByProductId } from '../../lib/domainProduct';

import { useGlobalProps } from '../../redux/hooks';
import { DEAL_DETAILS_PAGE_URL } from '../constants/rewards';
import { DealsTypesMap, MainDealsTypesByStructure } from '../helpers/dealHelper';

interface IDealHook {
    getDealImage: (id: string) => Asset;
    getDealRedirectUrlByRedeem: (
        offerType: TOfferTypeModel,
        eligibleIds: IRewardMenuIdModel[],
        buyCount: number,
        isIncludesAll?: boolean
    ) => string;
}

export default function useDeal(): IDealHook {
    const globalProps = useGlobalProps();

    const getDealRedirectUrlByRedeem = (
        offerType: TOfferTypeModel,
        eligibleIds: IRewardMenuIdModel[],
        buyCount: number,
        isIncludesAll?: boolean
    ) => {
        // MENU
        if (isIncludesAll) {
            return '/menu';
        }

        // DEAL PDP
        if (
            DealsTypesMap[offerType].mainType === MainDealsTypesByStructure.BuyX_GetY ||
            (DealsTypesMap[offerType].mainType === MainDealsTypesByStructure.BuyX &&
                (eligibleIds.length > 1 || (eligibleIds.length === 1 && buyCount > 1)))
        ) {
            return DEAL_DETAILS_PAGE_URL;
        }

        // PDP
        const productId = eligibleIds[0]?.menuId;
        const productPagePathByProductId = getProductPagePathByProductId(
            productId,
            globalProps.productDetailsPagePaths
        );

        return productPagePathByProductId.productPath;
    };

    const getDealImage = (id: string) => {
        const { dealItemsById } = globalProps;
        const deal = dealItemsById[id];
        const dealImage = deal?.fields.image || dealItemsById['default']?.fields.image;

        return dealImage;
    };

    return { getDealImage, getDealRedirectUrlByRedeem };
}
