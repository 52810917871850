import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useAppDispatch } from '../../../redux/store';
import { GTM_ADD_TO_BAG_DEAL } from '../../../common/services/gtmService/constants';
import { TOffersUnionModel } from '../../../@generated/webExpApi';
import { IRemainingMenuIds } from '../../../common/helpers/dealHelper';
import { useGetDealTallyItemById } from '../../../redux/hooks/dealPdp';
import { useDealDisplayProductsByMenuIds } from '../../../redux/hooks/pdp';
import useBag from '../../../redux/hooks/useBag';
import useGlobalProps from '../../../redux/hooks/useGlobalProps';
import { SuggestProductModal } from '../suggestProductModal/suggestProductModal';
import { TITLE } from './suggestProductModalContainer.constants';

interface ISuggestProductModalContainer {
    isOpen: boolean;
    onClose: () => void;
    offer: TOffersUnionModel;
    remainingBuyIds?: IRemainingMenuIds;
    remainingGetIds?: IRemainingMenuIds;
    remainingEligibleIds?: IRemainingMenuIds;
}

export const SuggestProductModalContainer: FC<ISuggestProductModalContainer> = (props) => {
    const { isOpen, onClose, offer, remainingBuyIds, remainingGetIds, remainingEligibleIds } = props;
    const { productDetailsPagePaths, productsById } = useGlobalProps();

    const dispatch = useAppDispatch();

    const [pickIndex, setPickIndex] = useState(0);

    const picks: IRemainingMenuIds[] = [
        remainingBuyIds,
        remainingGetIds,
        ...Array(remainingEligibleIds?.count).fill({ ...remainingEligibleIds, count: 1 }),
    ].filter((pick) => Boolean(pick?.menuIds));

    const maxPickIndex = picks.length - 1;

    const isLastPick = pickIndex === maxPickIndex;

    const activePick = picks[pickIndex];
    const allMenuIds = picks.reduce((acc, current) => {
        return [...acc, ...current.menuIds];
    }, []);

    const currentDisplayProducts = useDealDisplayProductsByMenuIds(
        activePick.menuIds,
        productsById,
        productDetailsPagePaths
    );
    const allDisplayProducts = useDealDisplayProductsByMenuIds(allMenuIds, productsById, productDetailsPagePaths);

    const [selectedIdsByPicks, setSelectedIdsByPicks] = useState<string[][]>(Array(picks.length).fill([]));

    const onNextClick = () => setPickIndex(pickIndex + 1);

    const onBackClick = () => setPickIndex(pickIndex - 1);

    const defaultTallyItem = useGetDealTallyItemById();
    const bag = useBag();

    const handleAddToBag = useCallback(() => {
        const productNames = [];
        selectedIdsByPicks
            .flatMap((id) => id)
            .forEach((productId) => {
                productNames.push(
                    allDisplayProducts.find((product) => product.displayProductDetails.productId === productId)
                        ?.displayName
                );

                bag.actions.putToBag({
                    pdpTallyItem: defaultTallyItem(pickIndex, productId),
                });
            });
        bag.actions.addDealToBag({ id: offer.userOfferId });

        dispatch({ type: GTM_ADD_TO_BAG_DEAL, payload: { productNames: productNames.join(', ') } });
        onClose();
    }, [
        selectedIdsByPicks,
        bag.actions,
        offer.userOfferId,
        dispatch,
        onClose,
        allDisplayProducts,
        defaultTallyItem,
        pickIndex,
    ]);

    const isInitialRender = useRef(true);

    const activePickSelectedIds = selectedIdsByPicks[pickIndex];

    const handleProductSelect = useCallback(
        (id: string) => {
            if (activePick.count === 1) {
                !activePickSelectedIds.includes(id) &&
                    setSelectedIdsByPicks([
                        ...selectedIdsByPicks.slice(0, pickIndex),
                        [id],
                        ...selectedIdsByPicks.slice(pickIndex + 1),
                    ]);
            } else {
                const newActivePickSelectedIds =
                    !selectedIdsByPicks[pickIndex].includes(id) &&
                    activePick.count > selectedIdsByPicks[pickIndex].length
                        ? [...activePickSelectedIds, id]
                        : activePickSelectedIds.filter((selectedProductId) => selectedProductId !== id);

                setSelectedIdsByPicks([
                    ...selectedIdsByPicks.slice(0, pickIndex),
                    newActivePickSelectedIds,
                    ...selectedIdsByPicks.slice(pickIndex + 1),
                ]);
            }
        },
        [activePick.count, activePickSelectedIds, pickIndex, selectedIdsByPicks]
    );

    useEffect(() => {
        if (!activePickSelectedIds.length && isInitialRender.current) {
            const firstProductId = currentDisplayProducts[0].displayProductDetails.productId;

            handleProductSelect(firstProductId);
        }

        if (isInitialRender.current) {
            isInitialRender.current = false;
        }
    }, [currentDisplayProducts, pickIndex, activePickSelectedIds, handleProductSelect]);

    return (
        <SuggestProductModal
            isOpen={isOpen}
            onClose={onClose}
            onNextClick={onNextClick}
            onBackClick={onBackClick}
            handleAddToBag={handleAddToBag}
            handleProductSelect={handleProductSelect}
            title={TITLE}
            subtitle="Select"
            displayProducts={currentDisplayProducts}
            selectedItems={activePickSelectedIds}
            maxPickIndex={maxPickIndex}
            isLastPick={isLastPick}
            maxQuantity={activePick.count}
        />
    );
};
