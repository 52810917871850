import React from 'react';

import { IProduct } from '../../../@generated/@types/contentful';
import { getCookieValue } from '../../../common/helpers/cookieHelper';
import { getRelatedProduct } from '../../../lib/domainProduct';
import { useBag, useGlobalProps, useSelectedSell } from '../../../redux/hooks';
import HScroller from '../../atoms/HScroller';
import VerticalProductCardContainer from '../../organisms/verticalProductCardContainer';
import { TITLE_TEXT } from './constants';

import styles from './index.module.css';

function RecommendedItems(): JSX.Element {
    const { recommendationId, recommendedProducts, recommendedProductIds, correlationId } = useSelectedSell();
    const customerType = getCookieValue('customerType') === 'PC' ? 'Polaris' : 'Learning';

    const globalProps = useGlobalProps();
    const bag = useBag();

    const cleanProductName = (product) => {
        let productName = product.fields.name;
        // strip spaces and replace with underscores
        productName = productName.replace(/ /g, '_');
        // strip registered symbols and all other weird chars
        productName = productName.replace(/[^a-zA-Z0-9_-]/g, '');
        return productName;
    };

    const closeBag = () => bag.actions.toggleIsOpen({ isOpen: false });

    const renderItems = () => {
        return recommendedProductIds
            .map((productId, index) => {
                const contentfulProduct =
                    globalProps.productsById[productId] ||
                    getRelatedProduct(recommendedProducts, globalProps.productsById);

                if (contentfulProduct) {
                    const dataGtmId = `${customerType}-${index + 1}-${cleanProductName(
                        contentfulProduct
                    )}|${correlationId}`;

                    return (
                        <VerticalProductCardContainer
                            key={productId}
                            item={contentfulProduct as IProduct}
                            recommendationId={recommendationId}
                            isSideScroll={true}
                            category={'recommended-items'}
                            onModify={closeBag}
                            onCardLinkClick={closeBag}
                            crossSellGtmId={dataGtmId}
                            containerClass="crossSell_item"
                            addClass="crossSell_addToBag"
                            modifyClass="crossSell_modify"
                        />
                    );
                }
            })
            .filter((product) => !!product);
    };

    // eslint-disable-next-line
    const productCardsForView = renderItems();

    return (
        !!productCardsForView.length && (
            <div className={styles.productsContainer}>
                <div className={styles.productsHeading}>{TITLE_TEXT}</div>
                <div className={styles.scrollerContainer}>
                    <HScroller
                        scrollStep={340}
                        prevButtonClassName={styles.leftArrow}
                        nextButtonClassName={styles.rightArrow}
                        itemType="product"
                        listClassName={styles.scrollerlist}
                    >
                        {productCardsForView}
                    </HScroller>
                </div>
            </div>
        )
    );
}

export default RecommendedItems;
