import React, { FC } from 'react';
import Radio, { RadioProps } from '@material-ui/core/Radio';
import classNames from 'classnames';

import styles from './radioButton.module.css';

interface IRadioButtonProps extends Omit<RadioProps, 'size'> {
    size?: 'small' | 'standard' | 'large';
}

const RadioButton: FC<IRadioButtonProps> = (props) => {
    const { className, size = 'standard', ...restProps } = props;

    return (
        <Radio
            disableRipple
            className={classNames(className, styles.radioButton, styles[size])}
            checkedIcon={<span className={classNames(styles.icon, styles.checkedIcon)} />}
            icon={<span className={styles.icon} />}
            {...restProps}
        />
    );
};

export default RadioButton;
