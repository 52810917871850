import React from 'react';
import classnames from 'classnames';
import { useRewards } from '../../../redux/hooks';

import styles from './bagLoyaltyBanner.module.css';
import getBrandInfo from '../../../lib/brandInfo';

const { brandId } = getBrandInfo();
const brandIdLowercase = brandId.toLowerCase();

const BagLoyaltyBanner = (): JSX.Element => {
    const rewards = useRewards();

    if (!rewards.totalCount) return null;

    return (
        <div className={styles.loyaltyBanner}>
            <img src={`/brands/${brandIdLowercase}/cup.svg`} alt="cup" />
            <div className={styles.loyaltyBannerLabelContainer}>
                <span role="heading" className="t-product-title">
                    Have reward certificates?
                </span>
                <span className={classnames('t-paragraph-hint', styles.loyaltyBannerLabelHint)}>
                    Reward certificates applied at checkout
                </span>
            </div>
        </div>
    );
};

export default BagLoyaltyBanner;
