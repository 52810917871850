import React from 'react';
import classnames from 'classnames';
import { TOffersUnionModel } from '../../../@generated/webExpApi/models';
import { useAppDispatch } from '../../../redux/store';
import { GTM_REDEEM_DEAL_FROM_BAG, GTM_VIEW_DEAL } from '../../../common/services/gtmService/constants';
import { useDevice } from '../../../common/hooks/useDevice';
import { InspireLink, InspireLinkButton } from '../link';
import ContentfulImage from '../ContentfulImage';
import { isValid } from '../../../common/helpers/dateTime';
import styles from './dealItem.module.css';
import { Divider } from '../divider';
import { CONSTANTS } from '../../../constants/en/commons';
import { dateLabelText } from '../../../common/helpers/dataLabelText';

export interface IDealItemProps {
    item: TOffersUnionModel;
    isAnyRedeemed?: boolean;
    onClick?: (id: string) => void;
    onCloseBag: () => void;
}

const DealItem = (props: IDealItemProps): JSX.Element => {
    const { item, onClick, onCloseBag } = props;
    const { REDEEM, VIEW_DEAL } = CONSTANTS;
    const { name, userOfferId, imageUrl, endDateTime } = item;

    const dispatch = useAppDispatch();
    const { deviceType } = useDevice();

    const expiresText = isValid(new Date(endDateTime)) ? dateLabelText(endDateTime, 'MM/dd') : '';

    const handleRedeem = () => {
        dispatch({ type: GTM_REDEEM_DEAL_FROM_BAG, payload: { offerName: name, device: deviceType } });

        if (typeof onClick === 'function') {
            onClick(userOfferId);
        }
    };

    const handleViewDealClick = () => {
        dispatch({ type: GTM_VIEW_DEAL, payload: { label: 'View Deal', offerName: name } });
        onCloseBag();
    };

    return (
        <div className={styles.itemContainer}>
            <div className={styles.imgColumn} data-testid="available-deals-image">
                {imageUrl && (
                    <ContentfulImage imageUrl={imageUrl} maxWidth={93} className={styles.dealImage} imageAlt="Deal" />
                )}
            </div>
            <div className={styles.detailsColumn}>
                <span
                    className={classnames('t-subheader-universal-smaller', styles.dealHeader)}
                    data-testid="available-deals-name"
                >
                    {name}
                </span>
                {!!expiresText && (
                    <span className={styles.expirationText} data-testid="available-deals-expiration-date">
                        {expiresText}
                    </span>
                )}
                <div className={styles.buttonsBlock}>
                    <InspireLinkButton
                        role="link"
                        aria-label="Redeem"
                        data-gtm-id={`cartRedeem-${name}`}
                        onClick={handleRedeem}
                        linkType="secondary"
                        dataTestId="redeem-button"
                    >
                        {REDEEM}
                    </InspireLinkButton>
                    <Divider className={styles.divider} />

                    <InspireLink
                        link={`/account/deals/deal?id=${userOfferId}`}
                        type="secondary"
                        dataTestId="view-deal-button"
                        aria-label="View deal"
                        className={styles.vewDealButton}
                        onClick={handleViewDealClick}
                    >
                        {VIEW_DEAL}
                    </InspireLink>
                </div>
            </div>
        </div>
    );
};

export default DealItem;
