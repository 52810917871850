import React, { useCallback } from 'react';
import { useAppDispatch } from '../../../redux/store';
import classnames from 'classnames';
import { isValid, format } from '../../../common/helpers/dateTime';
import { InspireLinkButton } from '../../atoms/link';
import { useBag } from '../../../redux/hooks';
import { useDevice } from '../../../common/hooks/useDevice';
import { GTM_REDEEM_DEAL_FROM_BAG } from '../../../common/services/gtmService/constants';

import styles from './item.module.css';
import { TOffersUnionModel } from '../../../@generated/webExpApi';

const BagSuggestedDeal: React.FC<{ fullMatchOffer: TOffersUnionModel }> = ({ fullMatchOffer }) => {
    const bag = useBag();

    const dispatch = useAppDispatch();
    const { deviceType } = useDevice();

    const handleRedeemClick = useCallback(() => {
        bag.actions.addDealToBag({ id: fullMatchOffer.userOfferId });

        dispatch({ type: GTM_REDEEM_DEAL_FROM_BAG, payload: { offerName: fullMatchOffer.name, device: deviceType } });
    }, [bag.actions, fullMatchOffer, dispatch, deviceType]);

    const expiresDate = isValid(new Date(fullMatchOffer.endDateTime))
        ? format(new Date(fullMatchOffer.endDateTime), 'MM/dd')
        : '';

    return (
        <div className={styles.suggestedDealContainer}>
            <div className={styles.dealInfoContainer}>
                <span
                    className={classnames('t-subheader-universal-smaller', 'truncate-at-2', styles.offerHeader)}
                    data-testid="suggested-deal-name"
                >
                    {fullMatchOffer.name}
                </span>
                {!!expiresDate && (
                    <span
                        className={styles.offerDateHeader}
                        data-testid="suggested-deal-expires-date"
                    >{`Expires ${expiresDate}`}</span>
                )}
            </div>
            <div className={styles.offerButtonSection}>
                <InspireLinkButton
                    role="link"
                    aria-label="redeem-deal"
                    data-gtm-id={`redeem-${fullMatchOffer.name}`}
                    onClick={handleRedeemClick}
                    linkType="secondary"
                    dataTestId="redeem-suggested-deal-button"
                    className={classnames(styles.offerButton, styles.offerButtonRedeem)}
                >
                    Redeem
                </InspireLinkButton>
            </div>
        </div>
    );
};

export default BagSuggestedDeal;
