import React from 'react';

import HScroller from '../HScroller';
import DealItem from '../dealItem';
import { isSessionMRewardsOn, isSonicOMSRewardsOn } from '../../../lib/getFeatureFlags';

import styles from './userDeals.module.css';
import { Offers } from '../../../redux/rewards';
import { PayloadAction } from '@reduxjs/toolkit';
import { AddDealToBagPayload } from '../../../redux/bag';
import { InspireLink } from '../link';
import { COUNT_DEALS_TO_SHOW, REDEEM_TITLE_TEXT, VIEW_ALL_REDEEM_LINK } from './constants';
import classNames from 'classnames';
import { isOfferModel } from '../../../common/helpers/isOfferModel';
import { CONSTANTS } from '../../../constants/en/commons';

export interface IUserDealsProps {
    offers: Offers;
    isCurrentLocationOAAvailable: boolean;
    onAddDealToBag: (payload: AddDealToBagPayload) => PayloadAction<AddDealToBagPayload>;
    dealId: string;
    disabled?: boolean;
    closeBag: () => void;
}

const UserDeals = ({
    offers,
    isCurrentLocationOAAvailable,
    onAddDealToBag,
    dealId,
    disabled = false,
    closeBag,
}: IUserDealsProps): JSX.Element => {
    const { VIEW_ALL_LOWERCASE, LIMITED_TO_DEAL } = CONSTANTS;
    if (!offers.length || !isCurrentLocationOAAvailable || !(isSessionMRewardsOn() || isSonicOMSRewardsOn())) {
        return null;
    }

    const handleDealItemClick = (id: string) => {
        onAddDealToBag({ id });
    };

    const isAnyRedeemed = offers.some((item) => {
        return dealId === item.userOfferId;
    });

    const renderItems = () => {
        const resultOffers: Offers = offers
            .filter(isOfferModel)
            .filter((item) => dealId !== item.userOfferId && !item.isRedeemableInStoreOnly);
        return resultOffers
            .splice(0, COUNT_DEALS_TO_SHOW)
            .map((offer) => (
                <DealItem
                    key={offer.userOfferId}
                    item={offer}
                    onClick={handleDealItemClick}
                    onCloseBag={closeBag}
                    isAnyRedeemed={isAnyRedeemed}
                />
            ));
    };

    return (
        <div className={styles.dealsContainer}>
            <div className={styles.dealsHeading}>
                <h4 className="t-header-card-title" data-testid="redeem-your-deal-header">
                    {REDEEM_TITLE_TEXT}
                </h4>
                <InspireLink
                    link={VIEW_ALL_REDEEM_LINK}
                    type="secondary"
                    dataTestId="view-all-button"
                    aria-label="Redeem"
                    data-gtm-id={`cartRedeem-${name}`}
                    onClick={closeBag}
                    className={styles.viewAllLink}
                >
                    {VIEW_ALL_LOWERCASE}
                </InspireLink>
            </div>
            <HScroller
                className={styles.carouselContainer}
                scrollStep={338}
                prevButtonClassName={styles.leftArrow}
                nextButtonClassName={styles.rightArrow}
                itemType="deal"
                disabled={disabled}
            >
                {renderItems()}
            </HScroller>
            <span
                className={classNames('t-paragraph-hint', styles.bottomMessage)}
                data-testid="available-deals-limit-text"
            >
                {LIMITED_TO_DEAL}
            </span>
        </div>
    );
};

export default UserDeals;
