import React from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';
import { InspireButton } from '../../../atoms/button';
import SectionHeader from '../../../atoms/sectionHeader';
import TextField from '../../../atoms/TextField';
import Icon from '../../../atoms/BrandIcon';
import styles from './promoCode.module.css';

interface IPromoCode {
    isError?: boolean;
    disabled?: boolean;
    isApplied?: boolean;
    inputValue?: string;
    helperText?: string;
    isValidationError?: boolean;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onApplyClick?: () => void;
    onRemoveClick?: () => void;
    onKeyPress?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}

export const PLACEHOLDER_TEXT = 'Enter promo code';
export const SECTION_HEADER_TEXT = 'Promo Code';

const renderStartAdornment = (isError?: boolean, isApplied?: boolean): React.ReactElement => {
    return !isError && isApplied ? (
        <InputAdornment position="start" classes={{ positionStart: styles.positionStart }}>
            <Icon icon="action-check" size="s" className={styles.success} data-testid="bag-promo-success-icon" />
        </InputAdornment>
    ) : null;
};

const renderEndAdornment = (
    isError?: boolean,
    disabled?: boolean,
    isApplied?: boolean,
    isValidationError?: boolean,
    onApplyClick?: () => void,
    onRemoveClick?: () => void
): React.ReactElement => (
    <InputAdornment position="end">
        {(isError && isValidationError) || isApplied ? (
            <InspireButton disabled={disabled} type="secondary" className={styles.button} onClick={onRemoveClick}>
                Remove
            </InspireButton>
        ) : (
            <InspireButton disabled={disabled} type="secondary" className={styles.button} onClick={onApplyClick}>
                Apply
            </InspireButton>
        )}
    </InputAdornment>
);

export const PromoCode: React.FC<IPromoCode> = ({
    isError,
    disabled,
    isApplied,
    inputValue,
    helperText,
    isValidationError,
    onChange,
    onKeyPress,
    onApplyClick,
    onRemoveClick,
}) => {
    return (
        <section className={styles.container}>
            <SectionHeader text={SECTION_HEADER_TEXT} tag="h3" textClassName="t-header-h3" />
            <TextField
                error={isError}
                value={inputValue}
                disabled={disabled}
                onChange={onChange}
                onKeyPress={onKeyPress}
                helperText={helperText}
                className={styles.textField}
                placeholder={PLACEHOLDER_TEXT}
                InputProps={{
                    startAdornment: renderStartAdornment(isError, isApplied),
                    endAdornment: renderEndAdornment(
                        isError,
                        disabled,
                        isApplied,
                        isValidationError,
                        onApplyClick,
                        onRemoveClick
                    ),
                }}
            />
        </section>
    );
};
