import { useRef, useEffect } from 'react';

type Callback = (...args: never[]) => void;
type Timeout = (callback: Callback, ms: number) => ReturnType<typeof setTimeout>;

export const useSafeSetTimeout = (): Timeout => {
    const timeoutIdRef = useRef(null);
    const safeSetTimeout = (callback: Callback, timeout: number): ReturnType<typeof setTimeout> => {
        const timeoutId = setTimeout(callback, timeout);
        timeoutIdRef.current = timeoutId;
        return timeoutId;
    };

    useEffect(() => {
        return () => {
            const timeoutId = timeoutIdRef.current;
            if (timeoutId) clearTimeout(timeoutId);
        };
    }, []);

    return safeSetTimeout;
};
