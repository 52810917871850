import React, { FC } from 'react';
import numberToWords from '../../../common/helpers/numberToWords';
import { SuggestProductCardContainer } from '../../atoms/suggestProductCardContainer/suggestProductCardContainer';
import Modal from '@material-ui/core/Modal';
import Icon from '../../atoms/BrandIcon';
import TextWithTrademark from '../../atoms/textWithTrademark';
import { InspireLinkButton } from '../../atoms/link';
import { InspireButton } from '../../atoms/button';
import classnames from 'classnames';
import { IDealDisplayProduct } from '../../../redux/types';
import styles from './suggestProductModal.module.css';
import { useGlobalProps } from '../../../redux/hooks';
import { ADD_TO_BAG, SHOW_HINT_SECTION } from './suggestProductModal.constants';

interface ISuggestProductModal {
    onClose: () => void;
    onNextClick: () => void;
    onBackClick: () => void;
    handleAddToBag: () => void;
    handleProductSelect: (id: string) => void;
    isOpen: boolean;
    title: string;
    subtitle: string;
    displayProducts: IDealDisplayProduct[];
    selectedItems: string[];
    maxPickIndex: number;
    isLastPick: boolean;
    maxQuantity: number;
}

export const SuggestProductModal: FC<ISuggestProductModal> = (props) => {
    const {
        isOpen,
        onClose,
        title,
        subtitle,
        displayProducts,
        selectedItems,
        handleAddToBag,
        handleProductSelect,
        onNextClick,
        onBackClick,
        maxPickIndex,
        isLastPick,
        maxQuantity,
    } = props;

    const { productsById } = useGlobalProps();

    if (!displayProducts) {
        return null;
    }

    const isDisabled = selectedItems?.length < maxQuantity;

    return (
        <Modal open={isOpen} onClose={onClose} className={styles.modal}>
            <div className={styles.container}>
                <div className={styles.containerContent}>
                    <div className={styles.header}>
                        <div className={styles.headerTop}>
                            <InspireLinkButton
                                linkType="primary"
                                onClick={onClose}
                                className={styles.modalCloseBtn}
                                aria-label="Save & Close"
                                dataTestId="header-save-and-close"
                            >
                                <Icon onClick={onClose} icon="action-close" size="m" className={styles.closeIcon} />
                            </InspireLinkButton>
                        </div>
                        <div className="m-block-treatment m-block-treatment-lg">
                            <h1
                                className={classnames('m-block-treatment-subtitle', styles.subtitle)}
                                data-testid="modal-subtitle"
                            >
                                {subtitle}
                            </h1>
                            <div className={styles.titleWrapper}>
                                <TextWithTrademark
                                    tag="h2"
                                    text={title}
                                    className={classnames('m-block-treatment-title', styles.title)}
                                    dataTestId="modal-title"
                                />
                                {SHOW_HINT_SECTION && (
                                    <span
                                        className={classnames(styles.hintText, 't-paragraph-small')}
                                        data-testid="modal-hint-text"
                                    >{`(Select ${numberToWords(maxQuantity)})`}</span>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className={styles.content}>
                        <div className={styles.contentContainer}>
                            {displayProducts?.map((displayProduct, i) => {
                                const productId = displayProduct?.displayProductDetails?.productId;
                                const contentfulProduct = productsById[productId];
                                const isSelected = selectedItems.some((item) => item === productId);

                                return (
                                    <SuggestProductCardContainer
                                        key={`${productId} + ${i}`}
                                        displayProduct={displayProduct}
                                        item={contentfulProduct}
                                        isSelected={isSelected}
                                        isDisabled={
                                            !isSelected && selectedItems?.length === maxQuantity && maxQuantity > 1
                                        }
                                        maxQuantity={maxQuantity}
                                        handleProductSelect={handleProductSelect}
                                    />
                                );
                            })}
                        </div>
                    </div>
                    <div className={styles.footer}>
                        <Icon className={styles.footerIcon} icon={'animals-chicken'} />
                        <div className={styles.footerButtons}>
                            {maxPickIndex > 0 && !isLastPick && (
                                <InspireButton
                                    className={styles.footerButton}
                                    onClick={onNextClick}
                                    type="primary"
                                    text="Next"
                                    dataTestId="modal-next-button"
                                    disabled={isDisabled}
                                />
                            )}
                            {maxPickIndex > 0 && isLastPick && (
                                <>
                                    <InspireButton
                                        className={styles.footerButton}
                                        onClick={onBackClick}
                                        type="secondary"
                                        text="Back"
                                        dataTestId="modal-back-button"
                                    />
                                </>
                            )}
                            {isLastPick && (
                                <InspireButton
                                    className={styles.footerButton}
                                    onClick={handleAddToBag}
                                    type="primary"
                                    text={ADD_TO_BAG}
                                    dataTestId="modal-add-to-bag-button"
                                    disabled={isDisabled}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};
